import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';

/**
 * User store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
    ...mapActions({
      loadUsers:"user/LOAD_USERS",
      loadRoles:"user/LOAD_ROLES",
      countUsers::"user/COUNT_USERS",
      exportUsers: "user/EXPORT_USERS",
      updateUser:"user/UPDATE_USER",
      updateUserRole:"user/UPDATE_USER_ROLE",
      deleteUsers: "user/DELETE_USERS",
      hardDeleteUsers: "user/HARD_DELETE_USERS",
      restoreUsers: "user/RESTORE_USERS",
      invitAdminUsers: "user/INVIT_ADMIN_USERS",
      refreshInvitUsers: "user/REFRESH_INVIT_USERS",
      loadClientUser: "user/LOAD_CLIENT_USER"

    }),
    ...mapMutations({
      setUsers:"user/SET_USERS",
      setRoles:"user/SET_ROLES",
      setUsersCount:"user/SET_USERS_COUNT",
      updateUser:"user/UPDATE_USER",
    }),
  },

    computed: {
    ...mapState("user", ["users", "usersCount", "roles"]),
    }
 */

const getDefaultState = () => {
  return {
    users: [],
    usersCount: 0,
    usersPayload: null,
    clientUser: null,
    roles: [],
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {};

// mutations
const mutations = {
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} payload -  { users, query }
   *
   * set collections data users
   */
  SET_USERS: (state, { users }) => {
    state.users = users;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} roles
   *
   * set collections data roles
   */
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} payload - { count }
   *
   * set count data users
   */
  SET_USERS_COUNT: (state, { count }) => {
    state.usersCount = count;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update user object in collection from payload.user parameter
   */
  UPDATE_USER: (state, payload) => {
    const _index = state.users.findIndex(user => user._id === payload.user._id);
    if (_index >= 0) {
      Object.assign(state.users[_index], payload.user);
    }

    if (state.clientUser !== null && state.clientUser._id === payload.user._id) {
      Object.assign(state.clientUser, payload.user);
    }
  },

  REMOVE_USER: (state, userId) => {
    const index = state.users.findIndex(user => user._id === userId);
    if (index >= 0) {
      state.users.splice(index, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of users
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_USERS: async function (context, payload) {

    this.commit('user/UPDATE_ROOT_FIELD', {
      key: 'usersPayload',
      value: JSON.stringify(payload)
    });

    return this.$axios
      .get('/api/v1/users/list?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        users => {
          this.commit('user/SET_USERS', { users, payload });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'USERS are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * fetch collection of existing roles
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_ROLES: async function (context) {
    // don't reload roles list if already in state
    if (context.state.roles.length > 0) {
      console.log('LOAD_ROLES already in state');
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/users/roles')
      .then(r => r.data)
      .then(
        roles => {
          this.commit('user/SET_ROLES', roles);
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'ROLES are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * fetch number of users
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  COUNT_USERS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/users/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('user/SET_USERS_COUNT', { count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT USERS are loaded' });
          return true;
        },
        err => {
          if (err && err.response) {
            this.dispatch('api/API_ERROR', err.response.data);
          }
          return false;
        }
      );
  },

  /**
   * export users as csv
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  EXPORT_USERS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/users/export?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort)
      .then(r => r.data)
      .then(
        data => {
          let csvContent = "data:text/csv;charset=utf-8," + data;
          const link = document.createElement("a");
          link.setAttribute("href", encodeURI(csvContent));
          link.setAttribute("download", "users.csv");
          link.click();
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );;
  },

  /**
   * update a specific user role
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @return Promise
   */
  UPDATE_USER_ROLE: async function (context, { userId, name, value }) {
    return this.$axios
      .put('/api/v1/users/updateRole/' + userId, { name, value })
      .then(r => r.data)
      .then(
        user => {
          this.commit('user/UPDATE_USER', { user });
          this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.user.roleUpdated', display: true });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * update a specific user
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @return Promise
   */
  UPDATE_USER: async function (context, payload) {
    return this.$axios
      .put('/api/v1/users/' + payload.userId, payload)
      .then(r => r.data)
      .then(
        user => {
          this.commit('user/UPDATE_USER', { user });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * mark filtered users as deleted
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} query
  * @return Promise
  */
  DELETE_USERS: function (context, query = '') {
    return this.$axios
      .delete('/api/v1/users/softDelete/' + query)
      .then(r => r.data)
      .then(
        users => {
          for (const user of users) {
            this.commit('user/UPDATE_USER', { user });
          }
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'users has been deleted' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * hard delete user from bdd with all is dependancy (magazine, page, library...)
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} query
  * @return Promise
  */
  HARD_DELETE_USERS: function (context, query = '') {
    return this.$axios
      .delete('/api/v1/users/hardDelete/' + query)
      .then(r => r.data)
      .then(
        userIds => {
          for (const userId of userIds) {
            this.commit('user/REMOVE_USER', userId);
          }
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'user has been deleted' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * mark filtered users as not deleted
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} query
  * @return Promise
  */
  RESTORE_USERS: function (context, query = '') {
    return this.$axios
      .put('/api/v1/users/restore/' + query)
      .then(r => r.data)
      .then(
        users => {
          for (const user of users) {
            this.commit('user/UPDATE_USER', { user });
          }
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'users has been restored' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },


  /**
   * invit new list of user by emails
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @return Promise
   */
  INVIT_ADMIN_USERS: async function (context, { organization, emails, roles, tag, sendInvit }) {
    return this.$axios
      .post('/api/v1/users/invitAdmin', { organization, emails, roles, tag, sendInvit })
      .then(r => r.data)
      .then(
        users => {
          // refresh complete current user list
          if (context.state.usersPayload !== null) {
            const usersPayload = JSON.parse(context.state.usersPayload);
            this.commit('user/SET_USERS', { users: [] });
            this.commit('user/SET_USERS_COUNT', { count: 0 });
            this.dispatch('user/LOAD_USERS', usersPayload);
            this.dispatch('user/COUNT_USERS', usersPayload);
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', tr: 'serverResponse.user.created', trParams: { nb: users.length }, display: true });
          return users;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * refresh invitation code and send new invit email
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @return Promise
   */
  REFRESH_INVIT_USERS: async function (context, query = '') {
    return this.$axios
      .put('/api/v1/users/refreshInvit/' + query)
      .then(r => r.data)
      .then(
        users => {
          for (const user of users) {
            this.commit('user/UPDATE_USER', { user });
          }
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'users invit sended' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * fetch data of specific user
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_CLIENT_USER: async function (context, payload) {

    return this.$axios
      .get('/api/v1/users/clientUser/' + payload.userId)
      .then(r => r.data)
      .then(
        user => {
          this.commit('user/UPDATE_ROOT_FIELD', {
            key: 'clientUser',
            value: user
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'CLIENT_USER are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
