import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Utils from "~/common/utils/store";
/**
 * AuthStrategy store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadAuthStrategies:"authStrategy/LOAD_AUTHSTRATEGIES",
      countAuthStrategies: "authStrategy/COUNT_AUTHSTRATEGIES",
      loadAuthStrategy: "authStrategy/LOAD_AUTHSTRATEGY",
      saveAuthStrategy: "authStrategy/SAVE_AUTHSTRATEGY",
      deleteAuthStrategy: "authStrategy/DELETE_AUTHSTRATEGY",

      loadAuthResultMaps:"authStrategy/LOAD_AUTHRESULTMAPS",
      loadAuthResultMap: "authStrategy/LOAD_AUTHRESULTMAP",
      saveAuthResultMap: "authStrategy/SAVE_AUTHRESULTMAP",
      deleteAuthResultMap: "authStrategy/DELETE_AUTHRESULTMAP",
    }),
    ...mapMutations({
      updateAuthStrategyField: "authStrategy/UPDATE_FIELD",
      updateAuthResultMapField: "authStrategy/UPDATE_AUTHRESULTMAP_FIELD",
      updateAuthStrategyRootField: "authStrategy/UPDATE_ROOT_FIELD",
      updateAuthStrategyArray: "authStrategy/UPDATE_ARRAY",
      swapAuthStrategyArray: "authStrategy/SWAP_ARRAY",
      updateAuthStrategyRootArray: "authStrategy/UPDATE_ROOT_ARRAY",
      setCurrentAuthStrategy:"authStrategy/SET_CURRENT_AUTHSTRATEGY",
      setAuthStrategies:"authStrategy/SET_AUTHSTRATEGIES",
      addAuthStrategy:"authStrategy/ADD_AUTHSTRATEGY",
      updateAuthStrategy:"authStrategy/UPDATE_AUTHSTRATEGY",
      removeAuthStrategy:"authStrategy/REMOVE_AUTHSTRATEGY",
      resetAuthStrategy:"authStrategy/RESET_AUTHSTRATEGY",
      resetAuthStrategies:"authStrategy/RESET_AUTHSTRATEGIES",
      updateAuthResultMapField: "authStrategy/UPDATE_AUTHRESULTMAP_FIELD",
      resetAuthResultMap: "authStrategy/RESET_AUTHRESULTMAP",
      resetStateAuthStrategy:"authStrategy/RESET_STATE"
    }),
    },

      computed: {
    ...mapState("authStrategy", ["authStrategies","authStrategy","authStrategiesCount","authResultMaps","authResultMap"]),
    ...mapGetters("authStrategy", ["getDefaultAuthStrategy","getAuthStrategies","listAuthStrategyType", "listAuthResultMapKey"]),
      }
 */
/**
 * return default authStrategy object
 */
function initialAuthStrategyState() {
  return {
    _id: null,
    debugMode: false,
    strategy: 'oauth2', // ['oauth2', 'cas', 'cas-dynamic', 'mz1']
    key: null,
    clientId: null,
    clientSecret: null,
    scope: [],
    urlAuth: null,
    urlToken: null,
    urlData: null,
    authResultMap: null,
    apiAccessToken: '',
    apiAccessExpireAt: null
  }
}

/**
 * return default authResultMap object
 */
function initialAuthResultMapState() {
  return {
    _id: null,
    name: null,
    attributes: [],
  }
}

function _formatAuthStrategyForList(authStrategy) {
  // TODO : set/update specific field to set in AuthStrategies list if needed
  return authStrategy;
}
function _formatAuthResultMapForList(authResultMap) {
  // TODO : set/update specific field to set in AuthResultMaps list if needed
  return authResultMap;
}

const getDefaultState = () => {
  return {
    authStrategies: [],
    authStrategiesCount: 0,
    authStrategy: initialAuthStrategyState(),
    authResultMaps: [],
    authResultMap: initialAuthResultMapState()
  }
}

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  /**
   * return collection authStrategies
   */
  getAuthStrategies: state => state.authStrategies,

  /**
   * return defaut state of authStrategy object
   */
  getDefaultAuthStrategy: state => initialAuthStrategyState(),

  listAuthStrategyType: state => {
    return ['oauth2', 'cas', 'cas-dynamic', 'mz1'];
  },

  listAuthResultMapKey: state => {
    return [
      { key: 'userId' },
      { key: 'userLang', values: ['en', 'fr'] },
      { key: 'userProfile', values: ['teacher', 'student'] },
      { key: 'ssoIdentifier' },
      { key: 'firstName' },
      { key: 'lastName' },
    ];
  }
};
// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} authStrategies
   *
   * set collections data authStrategies
   */
  SET_AUTHSTRATEGIES: (state, authStrategies) => {
    state.authStrategies = authStrategies;
  },
  SET_AUTHRESULTMAPS: (state, authResultMaps) => {
    state.authResultMaps = authResultMaps;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add authStrategy object in collection from payload.authStrategy parameter
   */
  ADD_AUTHSTRATEGY: (state, payload) => {
    state.authStrategies.push(_formatAuthStrategyForList(payload.authStrategy));
  },
  ADD_AUTHRESULTMAP: (state, payload) => {
    state.authResultMaps.push(_formatAuthResultMapForList(payload.authResultMap));
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update authStrategy object in collection from payload.authStrategy parameter
   */
  UPDATE_AUTHSTRATEGY: (state, payload) => {
    const _index = state.authStrategies.findIndex(authStrategy => authStrategy._id === payload.authStrategy._id);
    if (_index >= 0) {
      Object.assign(state.authStrategies[_index], _formatAuthStrategyForList(payload.authStrategy));
    }
  },
  UPDATE_AUTHRESULTMAP: (state, payload) => {
    const _index = state.authResultMaps.findIndex(authResultMap => authResultMap._id === payload.authResultMap._id);
    if (_index >= 0) {
      Object.assign(state.authResultMaps[_index], _formatAuthStrategyForList(payload.authResultMap));
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} authStrategyID
   *
   * remove authStrategy object from collection with authStrategyID
   */
  REMOVE_AUTHSTRATEGY: (state, authStrategyID) => {
    const _index = state.authStrategies.findIndex(authStrategy => authStrategy._id === authStrategyID);
    if (_index >= 0) {
      state.authStrategies.splice(_index, 1);
    }
  },
  REMOVE_AUTHRESULTMAP: (state, authResultMapID) => {
    const _index = state.authResultMaps.findIndex(authResultMap => authResultMap._id === authResultMapID);
    if (_index >= 0) {
      state.authResultMaps.splice(_index, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} authStrategyID
   *
   * set current authStrategy object from collection with authStrategyID
   */
  SET_CURRENT_AUTHSTRATEGY: (state, authStrategyID) => {
    if (authStrategyID === null) {
      state.authStrategy = Object.assign({}, initialAuthStrategyState());
    }
    else {
      state.authStrategy = Object.assign(
        {},
        state.authStrategy,
        state.authStrategies.find(authStrategy => authStrategy._id == authStrategyID)
      );
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.authStrategy
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.authStrategy, value);
  },

  /**
  * @param {Object} state - https://vuex.vuejs.org/guide/state.html
  * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
  *
  * update one field of state.authResultMap
  */
  UPDATE_AUTHRESULTMAP_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.authResultMap, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.authStrategy
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state.authStrategy[payload.key].findIndex(payload.cb) : state.authStrategy[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.authStrategy[payload.key].splice(_index, 1);
      }
    }
    else {
      state.authStrategy[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.authStrategy
   */
  SWAP_ARRAY: (state, payload) => {
    let oldVal = state.authStrategy[payload.key][payload.from];
    state.authStrategy[payload.key][payload.from] = state.authStrategy[payload.key][payload.to];
    Vue.set(state.authStrategy[payload.key], payload.to, oldVal)
  },

  /**
 * @param {Object} state - https://vuex.vuejs.org/guide/state.html
 * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
 *
 * update one array field of state
 */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    }
    else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.authStrategy
   */
  RESET_AUTHSTRATEGY: (state) => {
    state.authStrategy = Object.assign({}, initialAuthStrategyState());
  },
  RESET_AUTHRESULTMAP: (state) => {
    state.authResultMap = Object.assign({}, initialAuthResultMapState());
  },
  RESET_AUTHSTRATEGIES: (state) => {
    state.authStrategies = [];
    state.authStrategiesCount = 0;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState);
  }

};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of authStrategies
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_AUTHSTRATEGIES: async function (context, payload = { filter: {} }) {

    return this.$axios.get("/api/v1/authStrategy?filter=" + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        authStrategies => {
          this.commit("authStrategy/SET_AUTHSTRATEGIES", authStrategies);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'AUTHSTRATEGIES are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);

          return false;
        }
      );
  },

  /**
  * fetch number of authStrategies
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} query - see more https://github.com/loris/api-query-params
  * @return Promise
  */
  COUNT_AUTHSTRATEGIES: async function (context, payload) {
    return this.$axios
      .get('/api/v1/authStrategy/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('authStrategy/UPDATE_ROOT_FIELD', { key: 'authStrategiesCount', value: count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT AUTHSTRATEGIES are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * fetch current authStrategy object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} authStrategyID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_AUTHSTRATEGY: async function (context, payload) {
    // don't reload authStrategy if already in state
    if (payload === context.state.authStrategy._id) {
      console.log('LOAD_AUTHSTRATEGY already in state - ' + payload);
      return Promise.resolve(true);
    }

    return this.$axios.get("/api/v1/authStrategy/" + Utils.normalizePayloadURI(payload, 'authStrategyId'))
      .then(r => r.data)
      .then(
        authStrategy => {

          this.commit("authStrategy/UPDATE_FIELD", { key: 'debugMode', value: authStrategy.debugMode });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'strategy', value: authStrategy.strategy });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'key', value: authStrategy.key });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'clientId', value: authStrategy.clientId });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'clientSecret', value: authStrategy.clientSecret });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'scope', value: authStrategy.scope });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'urlAuth', value: authStrategy.urlAuth });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'urlToken', value: authStrategy.urlToken });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'urlData', value: authStrategy.urlData });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'authResultMap', value: authStrategy.authResultMap });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'apiAccessToken', value: authStrategy.apiAccessToken });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'apiAccessExpireAt', value: authStrategy.apiAccessExpireAt });
          this.commit("authStrategy/UPDATE_FIELD", { key: '_id', value: authStrategy._id });

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'authStrategy is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * upsert current authStrategy object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_AUTHSTRATEGY: function (context, payload) {
    let method = context.state.authStrategy._id === null ? "post" : "put";
    let updateId = context.state.authStrategy._id === null ? "" : context.state.authStrategy._id;

    return this.$axios[method]("/api/v1/authStrategy/" + updateId, context.state.authStrategy)
      .then(r => r.data)
      .then(
        authStrategy => {
          this.commit("authStrategy/UPDATE_FIELD", { key: '_id', value: authStrategy._id });

          this.commit("authStrategy/UPDATE_FIELD", { key: 'debugMode', value: authStrategy.debugMode });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'strategy', value: authStrategy.strategy });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'key', value: authStrategy.key });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'clientId', value: authStrategy.clientId });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'clientSecret', value: authStrategy.clientSecret });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'scope', value: authStrategy.scope });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'urlAuth', value: authStrategy.urlAuth });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'urlToken', value: authStrategy.urlToken });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'urlData', value: authStrategy.urlData });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'authResultMap', value: authStrategy.authResultMap });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'apiAccessToken', value: authStrategy.apiAccessToken });
          this.commit("authStrategy/UPDATE_FIELD", { key: 'apiAccessExpireAt', value: authStrategy.apiAccessExpireAt });

          if (updateId.length === 0) {
            this.commit("authStrategy/ADD_AUTHSTRATEGY", { authStrategy });
          } else {
            this.commit("authStrategy/UPDATE_AUTHSTRATEGY", { authStrategy });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'authStrategy is saved', display: true });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * delete current authStrategy object
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  DELETE_AUTHSTRATEGY: function (context, payload) {
    let id = payload.id || context.state.authStrategy._id

    return this.$axios.delete("/api/v1/authStrategy/" + id)
      .then(r => r.data)
      .then(
        authStrategy => {
          this.commit("authStrategy/REMOVE_AUTHSTRATEGY", id);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'authStrategy has been deleted' });

          this.commit("authStrategy/RESET_AUTHSTRATEGY");
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * fetch collection of authResultMaps
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} query - see more https://github.com/loris/api-query-params
  * @return Promise
  */
  LOAD_AUTHRESULTMAPS: async function (context, payload = { filter: {} }) {

    return this.$axios.get("/api/v1/authStrategy/authResultMap?filter=" + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        authResultMaps => {
          this.commit("authStrategy/SET_AUTHRESULTMAPS", authResultMaps);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'AUTHRESULTMAPS are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);

          return false;
        }
      );
  },

  /**
    * fetch current authResultMap object
    * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
    * @param {String} authStrategyID
    * @param {String} query -see populate
    * @return Promise
    */
  LOAD_AUTHRESULTMAP: async function (context, payload) {
    // don't reload authResultMap if already in state
    if (payload === context.state.authResultMap._id) {
      console.log('LOAD_AUTHRESULTMAP already in state - ' + payload);
      return Promise.resolve(true);
    }

    return this.$axios.get("/api/v1/authStrategy/authResultMap/" + Utils.normalizePayloadURI(payload, 'authResultMapId'))
      .then(r => r.data)
      .then(
        authResultMap => {

          this.commit("authStrategy/UPDATE_AUTHRESULTMAP_FIELD", { key: 'name', value: authResultMap.name });
          this.commit("authStrategy/UPDATE_AUTHRESULTMAP_FIELD", { key: 'attributes', value: authResultMap.attributes });
          this.commit("authStrategy/UPDATE_AUTHRESULTMAP_FIELD", { key: '_id', value: authResultMap._id });

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'authResultMap is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * upsert current authResultMap object
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  SAVE_AUTHRESULTMAP: function (context, payload) {
    let method = context.state.authResultMap._id === null ? "post" : "put";
    let updateId = context.state.authResultMap._id === null ? "" : context.state.authResultMap._id;

    console.log('SAVE_AUTHRESULTMAP : ' + method, JSON.parse(JSON.stringify(context.state.authResultMap)))

    return this.$axios[method]("/api/v1/authStrategy/authResultMap/" + updateId, context.state.authResultMap)
      .then(r => r.data)
      .then(
        authResultMap => {
          this.commit("authStrategy/UPDATE_AUTHRESULTMAP_FIELD", { key: '_id', value: authResultMap._id });

          this.commit("authStrategy/UPDATE_AUTHRESULTMAP_FIELD", { key: 'name', value: authResultMap.name });
          this.commit("authStrategy/UPDATE_AUTHRESULTMAP_FIELD", { key: 'attributes', value: authResultMap.attributes });

          if (updateId.length === 0) {
            this.commit("authStrategy/ADD_AUTHRESULTMAP", { authResultMap });
          } else {
            this.commit("authStrategy/UPDATE_AUTHRESULTMAP", { authResultMap });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'authResultMap is saved', display: true });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * delete current authResultMap object
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  DELETE_AUTHRESULTMAP: function (context, payload) {
    let id = payload.id || context.state.authResultMap._id

    return this.$axios.delete("/api/v1/authStrategy/authResultMap/" + id)
      .then(r => r.data)
      .then(
        authResultMap => {
          this.commit("authStrategy/REMOVE_AUTHRESULTMAP", id);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'authResultMap has been deleted' });

          this.commit("authStrategy/RESET_AUTHRESULTMAP");
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};



export default store;
