<template>
  <el-container id="app" v-bind:class="{ 'app-support': isLoggedAsSupport }">
    <!-- <span class="beta-tag">beta</span> -->
    <div class="content-sticky-alert">
      <el-alert
        v-show="displayStickyMessage !== null"
        :title="displayStickyMessage"
        type="warning"
        center
        :closable="false"
      >
      </el-alert>
    </div>

    <el-header class="app-header">
      <span class="nav-logo">
        <el-link
          @click="onClickMenuLink({ name: 'client' })"
          :underline="false"
        >
          <img src="/logo-madmagz.svg" height="40px" />
        </el-link>
      </span>
      <span class="header-content">
        <MzCheckOfferAccessButton
          v-if="headerType === 'home' && hasWorkspaceAccess"
          class="ml-2"
          :label="$t('public.layout.create_magazine')"
          @click="
            onClickMenuLink({ name: 'client-magazine-create-selectTemplate' })
          "
          :permission="'magazine:create'"
        />
        <EditMagazineHeader
          v-if="headerType === 'editMag' && hasWorkspaceAccess"
          class="ml-2"
        />
        <!-- <el-link
          icon="el-icon-la-comment la2x"
          class="ml-2 feedback-button"
          href="https://form.asana.com?hash=1507e2219e6f1a5dfea2d6351313475635ade74cdd54155e51a3124233f467b9&id=1147172036592997"
          target="_blank"
          type="warning"
          :underline="false"
        >{{ $t('public.layout.feedback_report') }}</el-link>-->
      </span>

      <!-- user cart access -->
      <span v-if="hasWorkspaceAccess" class="ml-1">
        <CurrentCartIcon @click="onClickCart" />
      </span>

      <!-- notification access -->
      <span v-if="hasWorkspaceAccess" class="ml-1">
        <MzIconButton
          src="/icons/notifications.svg"
          :keepFocusOnClick="true"
          @click="toogleDisplayNotifications"
          @blur.native="onBlurNotificationButton"
        />
        <NotificationMenu class="notifications" v-show="showNotifications" />
      </span>

      <!-- magazine access list -->
      <span v-if="hasWorkspaceAccess" class="ml-1">
        <el-popover
          placement="bottom"
          trigger="click"
          width="300"
          :visible-arrow="false"
          v-model="displayMagazinePopover"
        >
          <div
            v-if="
              getDirectAccessMagazines && getDirectAccessMagazines.length > 0
            "
          >
            <el-row class="popover-row">
              <h3>{{ $t("public.layout.my_magazines") }}</h3>
            </el-row>
            <el-divider class="full-popover-divider" />
            <div
              class="popover-row"
              v-for="mag in getDirectAccessMagazines"
              :key="mag._id"
            >
              <el-link @click="onClickMag(mag)" :underline="false">
                <span>{{ mag.name }}</span>
                <br />
                <small>{{ mag.issue }}</small>
              </el-link>
            </div>
            <el-divider class="full-popover-divider" />
          </div>
          <div class="popover-row">
            <el-row class="mt-1">
              <MzCheckOfferAccessButton
                :label="$t('public.layout.create_magazine')"
                buttonClass="el-col el-col-24"
                @click="
                  onClickMenuLink({
                    name: 'client-magazine-create-selectTemplate',
                  })
                "
                :permission="'magazine:create'"
              />
            </el-row>
            <el-row class="mt-0">
              <el-button
                class="mt-0 el-col el-col-24"
                type="info"
                @click="onClickMenuLink({ name: 'client' })"
                v-t="'public.edit_magazine.access_all_mags'"
                v-if="checkPerm('magazine:list')"
              ></el-button>
            </el-row>
          </div>
          <MzIconButton
            slot="reference"
            src="/icons/magazines.svg"
            :disableStopEvent="true"
          />
        </el-popover>
      </span>

      <!-- user menu -->
      <el-popover
        v-if="isLogged"
        placement="bottom"
        trigger="click"
        width="300"
        :visible-arrow="false"
        v-model="displayUserPopover"
      >
        <div>
          <el-row class="popover-row">
            <h3>{{ user.name }}</h3>
            <small v-if="user.email">{{ user.email }}</small>
          </el-row>
          <el-row class="popover-row">
            <el-tag
              v-if="checkPerm('organization:showIsAdmin')"
              effect="dark"
              size="mini"
              type="danger"
            >
              {{ $t("public.organization.userStatus.isAdmin") }}
            </el-tag>
            <el-tag
              v-if="checkPerm('workspace:showIsAdmin')"
              effect="dark"
              size="mini"
              type="warning"
            >
              {{ $t("public.workspace.userStatus.isAdmin") }}
            </el-tag>
          </el-row>

          <el-divider class="full-popover-divider" />

          <el-row class="popover-row">
            <SwitchWorkspaceButton> </SwitchWorkspaceButton>
          </el-row>

          <el-divider class="full-popover-divider" />

          <div class="popover-row" v-if="!isB2cOrganization">
            <el-link
              @click="onClickMenuLink({ name: 'client-offer' })"
              :underline="false"
            >
              <i class="el-icon-la-cog el-icon--left"></i>
              {{ $t("public.layout.my_offer") }}
            </el-link>
          </div>

          <div class="popover-row">
            <el-link
              @click="onClickMenuLink({ name: 'client-user' })"
              :underline="false"
            >
              <i class="el-icon-la-user el-icon--left"></i>
              {{ $t("public.layout.my_profile") }}
            </el-link>
          </div>

          <div class="popover-row">
            <el-link
              @click="onClickMenuLink({ name: 'client-order-list' })"
              :underline="false"
            >
              <i class="el-icon-la-shopping-cart el-icon--left"></i>
              {{ $t("public.layout.my_order_list") }}
            </el-link>
          </div>

          <div
            class="popover-row"
            v-if="
              checkPerm('workspace:show') &&
              checkPerm('workspace:showManagePage')
            "
          >
            <el-link
              :disabled="!hasWorkspaceAccess"
              @click="onClickMenuLink({ name: 'client-workspace' })"
              :underline="false"
            >
              <i class="el-icon-la-folder-o el-icon--left"></i>
              {{ $t("public.layout.my_workspace") }}</el-link
            >
          </div>
          <div
            class="popover-row"
            v-if="
              checkPerm('organization:show') &&
              checkPerm('organization:showManagePage')
            "
          >
            <el-link
              :disabled="!hasOrganizationAccess"
              @click="onClickMenuLink({ name: 'client-organization' })"
              :underline="false"
            >
              <i class="el-icon-la-users el-icon--left"></i>
              {{ $t("public.layout.my_organization") }}
            </el-link>
          </div>

          <div
            class="popover-row"
            v-if="!isLoggedAsSupport && checkPerm('admin:all')"
          >
            <el-link
              @click="onClickMenuLink({ name: 'admin' })"
              :underline="false"
            >
              <i class="el-icon-la-star-o el-icon--left"></i>
              {{ $t("public.layout.admin_interface") }}
            </el-link>
          </div>

          <div v-if="isLoggedAsSupport">
            <el-divider class="full-popover-divider">
              <small>{{ $t("public.layout.support_title") }}</small>
            </el-divider>
            <el-row class="popover-row">
              <i class="el-icon-la-user"></i>
              <small>{{ user.email ? user.email : user.name }}</small>
            </el-row>
            <el-row class="popover-row">
              <i class="el-icon-la-star"></i>
              <small>{{
                supportUser.email ? supportUser.email : supportUser.name
              }}</small>
            </el-row>
            <el-row class="popover-row">
              <el-link @click="logoutSupport" :underline="false">{{
                $t("public.layout.logout_support")
              }}</el-link>
            </el-row>
          </div>

          <el-divider class="full-popover-divider" />

          <div class="popover-row">
            <el-link
              href="https://help.madmagz.com/fr/"
              target="_blank"
              :underline="false"
            >
              {{ $t("public.layout.support") }}
              <i class="el-icon-la-external-link el-icon--right"></i>
            </el-link>
          </div>

          <div class="popover-row">
            <el-link @click="logout()" :underline="false">{{
              $t("public.layout.logout")
            }}</el-link>
          </div>
        </div>

        <div slot="reference" class="ml-1 mr-2 button-avatar">
          <MzUserAvatar :user="user" />
        </div>
      </el-popover>
    </el-header>

    <!-- <el-main v-if="!hasOrganizationAccess && !hasWorkspaceAccess">
      <el-row class="p-2 m-2">
        <el-col :span="24" class="text-style-2 text-center">
          Your workspace or organization is suspended.<br />
          Please contact your administrator or Madmagz sales team for more
          information
        </el-col>
      </el-row>
    </el-main> -->

    <el-main class="app-main" v-if="isAutorizedUser" :key="mainKey">
      <nuxt />
    </el-main>

    <div class="version">api: {{ apiBuild }} | app: {{ appBuild }}</div>

    <MaintenanceAlert />
    <InactiveUserAlert />

    <OfflineModal :visible="isOffline" />

    <!-- Google Tag Manager -->
    <script>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-5QHPJQQ9");
    </script>
    <!-- End Google Tag Manager -->
  </el-container>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

import MiscUtils from "~/common/utils/misc";
import MzIconButton from "~/components/MzIconButton";
import EditMagazineHeader from "~/components/public/EditMagazineHeader";
import OfflineModal from "~/components/OfflineModal";
import NotificationMenu from "~/components/NotificationMenu";
import MzCheckOfferAccessButton from "~/components/public/MzCheckOfferAccessButton";
import MaintenanceAlert from "~/components/MaintenanceAlert";
import InactiveUserAlert from "~/components/InactiveUserAlert";
import SwitchWorkspaceButton from "~/components/workspace/SwitchWorkspaceButton";
import MzUserAvatar from "~/components/user/MzUserAvatar";
import CurrentCartIcon from "~/components/order/CurrentCartIcon";

export default {
  components: {
    MzIconButton,
    EditMagazineHeader,
    OfflineModal,
    NotificationMenu,
    MzCheckOfferAccessButton,
    MaintenanceAlert,
    InactiveUserAlert,
    MzUserAvatar,
    SwitchWorkspaceButton,
    CurrentCartIcon,
  },
  computed: {
    ...mapGetters("api", [
      "checkPerm",
      "isLogged",
      "isLoggedAsSupport",
      "currentWorkspace",
      "currentOrganization",
      "getOrganizations",
      "getWorkspaces",
      "getUID",
      "isB2cOrganization",
    ]),
    ...mapState("api", ["user", "supportUser"]),
    ...mapState("app", ["apiBuild"]),
    ...mapGetters("app", ["appBuild"]),
    ...mapGetters("magazine", ["getDirectAccessMagazines"]),

    mainKey() {
      const key = this.currentWorkspace ? this.currentWorkspace._id : -1;
      console.log(
        "client main key " + key,
        JSON.parse(JSON.stringify(this.currentWorkspace))
      );
      return key;
    },
    headerType() {
      return this.$route.matched.map((r) => {
        return r.components.default.options
          ? r.components.default.options.layoutHeaderType
          : r.components.default.layoutHeaderType;
      })[0];
    },
    isOffline() {
      return !this.$online.online;
    },
    authorizedNotLogged() {
      return this.$route.matched.map((r) => {
        return r.components.default.options
          ? r.components.default.options.authorizedNotLogged
          : r.components.default.authorizedNotLogged;
      })[0];
    },
    displayStickyMessage() {
      if (
        this.currentOrganization &&
        this.currentOrganization.status !== "active"
      ) {
        return this.$t("public.client.alertOrganizationNotActive");
      } else if (
        this.currentWorkspace &&
        this.currentWorkspace.status !== "active"
      ) {
        return this.$t("public.client.alertWorkspaceNotActive");
      } else if (
        this.currentWorkspace &&
        this.currentOrganization &&
        !this.currentWorkspace.activeOffer &&
        !this.currentOrganization.activeOffer
      ) {
        return this.$t("public.client.alertWorkspaceOfferExpired");
      }
      return null;
    },
    hasWorkspaceAccess() {
      if (this.isLogged && this.currentWorkspace) {
        return true;
      }

      return false;
    },
    hasOrganizationAccess() {
      if (this.isLogged) {
        if (
          this.currentOrganization &&
          this.currentOrganization.status === "active"
        ) {
          return true;
        }
      }

      return false;
    },
  },
  data() {
    return {
      isAutorizedUser: true,
      showNotifications: false,
      displayUserPopover: false,
      displayMagazinePopover: false,
    };
  },
  created() {
    this.getBuildVersion();
  },
  mounted() {
    if (!this.authorizedNotLogged && !this.checkPerm("editor:all")) {
      this.isAutorizedUser = false;
      this.$alert(
        this.$t("layout.unauthorized_user.message"),
        this.$t("layout.unauthorized_user.title"),
        {
          confirmButtonText: this.$t("layout.unauthorized_user.button"),
          callback: this.logout,
        }
      );
    }
  },
  methods: {
    ...mapActions({
      doLogout: "api/API_LOGOUT",
      doLogoutSupport: "api/API_LOGOUT_SUPPORT",
      getBuildVersion: "app/GET_API_BUILD",
    }),

    logout() {
      this.isAutorizedUser = false;
      if (this.isLoggedAsSupport) {
        this.doLogoutSupport().then(() => {
          this.doLogout().then(() => {
            this.$go2Route({ name: "login" });
          });
        });
      } else {
        this.doLogout().then(() => {
          this.$go2Route({ name: "login" });
        });
      }
    },
    logoutSupport() {
      this.isAutorizedUser = false;
      this.doLogoutSupport().then((valid) => {
        if (valid) {
          this.$go2Route({
            name: "admin",
          });
        }
      });
    },
    onClickCart() {
      this.onClickMenuLink({ name: "client-order-cart" });
    },
    onClickMenuLink(params) {
      this.displayUserPopover = false;
      this.displayMagazinePopover = false;
      this.showNotifications = false;
      this.$go2Route(params);
    },
    toogleDisplayNotifications() {
      this.displayUserPopover = false;
      this.displayMagazinePopover = false;
      this.showNotifications = !this.showNotifications;
    },
    onBlurNotificationButton() {
      MiscUtils.debounce(
        () => {
          this.showNotifications = false;
        },
        500,
        this,
        `debounce_hide_notifs`
      )();
    },
    onClickMag(magazine) {
      this.onClickMenuLink({
        name: "client-magazine-edit-magazineId",
        params: { magazineId: magazine._id },
        query: {
          flatplan: "extended",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/css/mz-variables.scss";

.app-menu {
  & .el-link.header-link-primary {
    color: $public-color-blue-dark;
    font-size: 1.13rem;
    margin-top: $margin-1;
    margin-left: $margin-1;

    &:hover {
      color: $public-color-blue-dark;
    }
  }

  & .el-link.header-link-secondary {
    color: $public-color-blue-dark;
    font-size: 1.13rem;
    margin-top: 0.32rem;

    &:hover {
      color: $public-color-blue-dark;
    }
  }
}

.app-support {
  & .app-header {
    background-color: rgba(84, 232, 84, 0.33);
  }
}
.feedback-button {
  margin-right: 20px;
}
.notifications {
  max-width: 15rem;
  position: absolute;
  transform: translate(-1rem, 4rem);
  right: 0;
  top: 0;
  max-height: 80vh;
  overflow: auto;
}
.logo {
  cursor: pointer;
  width: 100px;
}
.logo-menu {
  width: 80px;
}

.version {
  font-size: 10px;
  position: absolute;
  bottom: 5px;
  right: 20px;
  color: #aaa;
  pointer-events: none;
}

.content-sticky-alert {
  width: 100vw;

  & .el-alert {
    padding: 0.5rem;
  }
}

.button-avatar {
  cursor: pointer;
}

.popover-row {
  margin: 0.25rem 1rem;

  & .el-link {
    color: $public-color-black;
    margin-bottom: 0.5rem;
    width: 100%;
    justify-content: start;

    & /deep/.el-link--inner {
      width: 100%;
      vertical-align: middle;

      & [class^="el-icon-la-"],
      [class*=" el-icon-la-"] {
        font-size: 1.5rem;
        vertical-align: middle;
      }

      & .el-icon--left {
        margin-right: 0.5rem;
      }

      & .el-icon--right {
        float: right;
      }
    }
  }
}

.full-popover-divider {
  width: calc(100% + 24px);
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: -12px !important;
  margin-right: 0;
}
</style>
