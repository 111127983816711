import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';
import MiscUtils from '~/common/utils/misc';
import tinycolor from 'tinycolor2';
/**
 * Palette store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadPalette: "palette/LOAD_PALETTE",
      loadThemePalettes:"palette/LOAD_THEME_PALETTES",
      updateThemePalette: "palette/UPDATE_THEME_PALETTE",
      copyThemePalette: "palette/COPY_THEME_PALETTE",
      deleteThemePalette: "palette/DELETE_THEME_PALETTE",
      savePaletteSwatchesAndUpdateOthers: "palette/SAVE_PALETTE_SWATCHES_AND_UPDATE_OTHERS",
      changeFabricObjectPropertyColor: "palette/CHANGE_FABRIC_OBJECT_PROPERTY_COLOR",
    }),
    ...mapMutations({
      updatePaletteField: "palette/UPDATE_FIELD",
      updatePaletteRootField: "palette/UPDATE_ROOT_FIELD",
      updatePaletteArray: "palette/UPDATE_ARRAY",
      addThemePalette:"palette/ADD_THEME_PALETTE",
      updatePalette:"palette/UPDATE_PALETTE",
      removePalette:"palette/REMOVE_PALETTE",
      resetPalette:"palette/RESET_PALETTE",
      resetStatePalette:"palette/RESET_STATE"
    }),
    },

      computed: {
    ...mapState("palette", ["palette","themePalettes","paletteDependantProperty","defaultPalettePublicColors"]),
    ...mapGetters("palette", []),
      }
 */
/**
 * return default palette object
 */
function initialState() {
  return {
    _id: null,
    name: 'New Palette',
    swatches: [],
    color: null
  };
}

const getDefaultState = () => {
  return {
    palette: initialState(),
    themePalettes: [],
    themePalettesLoadedId: null,
    paletteDependantProperty: ['fill', 'textBackgroundColor', 'stroke'],
    defaultPalettePublicColors: [/*'#000000ff', '#ccccccff', '#ffffffff'*/]
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  getColorBySwatchName: state => {
    return state.palette.swatches.reduce(
      (list, swatch) => {
        list[swatch.name] = swatch.color;
        return list;
      },
      {});
  }
};

// mutations
const mutations = {

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add palette object in themePalettes list
   */
  ADD_THEME_PALETTE: (state, payload) => {
    if (state.themePalettesLoadedId === payload.themeId) {
      state.themePalettes.push(payload.palette);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update palette object in collection from payload.palette parameter
   */
  UPDATE_PALETTE: (state, payload) => {
    const _indexThemePalettes = state.themePalettes.findIndex(palette => palette._id === payload.palette._id);
    if (_indexThemePalettes >= 0) {
      Object.assign(state.themePalettes[_indexThemePalettes], payload.palette);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} paletteID
   *
   * remove palette object from collection with paletteID
   */
  REMOVE_PALETTE: (state, paletteID) => {
    const _indexThemePalettes = state.themePalettes.findIndex(palette => palette._id === paletteID);
    if (_indexThemePalettes >= 0) {
      state.themePalettes.splice(_indexThemePalettes, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} paletteID
   *
   * set current palette object from collection with paletteID
   */
  SET_CURRENT_PALETTE: (state, paletteID) => {
    if (paletteID === null) {
      state.palette = Object.assign({}, initialState());
    } else {
      const findPalette = state.themePalettes.find(palette => palette._id == paletteID);
      if (!findPalette) {
        console.warn('invalid SET_CURRENT_PALETTE for ' + paletteID, JSON.parse(JSON.stringify(state.themePalettes)));
      }
      state.palette = Object.assign({}, state.palette, findPalette);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.palette
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.palette, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.palette
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb
        ? state.palette[payload.key].findIndex(payload.cb)
        : state.palette[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.palette[payload.key].splice(_index, 1);
      }
    } else if (payload.update === true) {
      const _index = payload.cb
        ? state.palette[payload.key].findIndex(payload.cb)
        : state.palette[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.palette[payload.key].splice(_index, 1, payload.value);
      }
    } else {
      state.palette[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.palette
   */
  RESET_PALETTE: state => {
    state.palette = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {

  /**
   * fetch collection of palettes for a specific theme
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} themeId
   * @return Promise
   */
  LOAD_THEME_PALETTES: async function (context, themeId) {
    // don't reload palettes list if already in state
    if (context.state.themePalettes.length > 0) {
      if (context.state.themePalettesLoadedId === themeId) {
        console.log('LOAD_THEME_PALETTES already in state for ' + themeId);
        return Promise.resolve(true);
      } else {
        console.log('LOAD_THEME_PALETTES for ' + themeId);
        // reset themePalettes list before reload for specific themeId
        this.commit('palette/UPDATE_ROOT_FIELD', {
          key: 'themePalettes',
          value: []
        });
      }
    }

    return this.$axios
      .get('/api/v1/palette?theme=' + themeId)
      .then(r => r.data)
      .then(
        palettes => {
          this.commit('palette/UPDATE_ROOT_FIELD', {
            key: 'themePalettesLoadedId',
            value: themeId
          });
          this.commit('palette/UPDATE_ROOT_FIELD', {
            key: 'themePalettes',
            value: palettes
          });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'PALETTES are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);

          return false;
        }
      );
  },

  /**
   * fetch current palette object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} paletteID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_PALETTE: async function (context, payload) {
    // don't reload palette if already in state
    if (payload === context.state.palette._id) {
      console.log('LOAD_PALETTE already in state - ' + payload);
      return Promise.resolve(true);
    } else {
      console.log('LOAD_PALETTE - ' + payload);
    }

    return this.$axios
      .get('/api/v1/palette/' + Utils.normalizePayloadURI(payload, 'paletteId'))
      .then(r => r.data)
      .then(
        palette => {
          this.commit('palette/UPDATE_FIELD', { key: 'name', value: palette.name });
          this.commit('palette/UPDATE_FIELD', { key: 'swatches', value: palette.swatches });
          this.commit('palette/UPDATE_FIELD', { key: 'color', value: palette.color });
          this.commit('palette/UPDATE_FIELD', { key: '_id', value: palette._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'palette is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * upsert current palette object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_PALETTE_SWATCHES_AND_UPDATE_OTHERS: function (context, payload) {
    let paletteId = context.state.palette._id;

    return this.$axios
      .put('/api/v1/palette/' + paletteId, { updateOthers: true, swatches: context.state.palette.swatches })
      .then(r => r.data)
      .then(
        palettes => {
          for (const palette of palettes) {
            this.commit('palette/UPDATE_PALETTE', { palette });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'palette is saved' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * update specific palette present in themePalettes list
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload {paletteId, key, value}
   * @return Promise
   */
  UPDATE_THEME_PALETTE: function (context, payload) {
    const _palette = context.state.themePalettes.find(value => value._id === payload.paletteId);
    if (_palette === undefined) {
      this.dispatch('api/API_ERROR', 'no palettes found in themePalettes with id ' + payload.paletteId);
      return false;
    }

    const _params = {};
    _params[payload.key] = payload.value;
    _params.updateOthers = payload.updateOthers;

    return this.$axios
      .put('/api/v1/palette/' + _palette._id, _params)
      .then(r => r.data)
      .then(
        palettes => {
          const list = !payload.updateOthers ? [palettes] : palettes;
          for (const palette of list) {
            if (palette._id === context.state.palette._id) {
              this.commit('palette/UPDATE_FIELD', { key: 'name', value: palette.name });
              this.commit('palette/UPDATE_FIELD', { key: 'swatches', value: palette.swatches });
              this.commit('palette/UPDATE_FIELD', { key: 'color', value: palette.color });
            }
            this.commit('palette/UPDATE_PALETTE', { palette });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'palette is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * copy an existing palette in same theme
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  COPY_THEME_PALETTE: function (context, payload) {
    return this.$axios
      .post('/api/v1/palette/' + payload.paletteId + '/copy/', { themeId: payload.themeId })
      .then(r => r.data)
      .then(
        palette => {
          this.commit('palette/ADD_THEME_PALETTE', { palette, themeId: payload.themeId });
          this.commit('theme/ADD_THEME_PALETTE', { palette, themeId: payload.themeId });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'palette is saved' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * delete palette present in theme palette list
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  DELETE_THEME_PALETTE: function (context, payload) {
    let id = payload.paletteId;

    return this.$axios
      .delete('/api/v1/palette/' + id)
      .then(r => r.data)
      .then(
        palette => {
          this.commit('palette/REMOVE_PALETTE', id);
          this.commit('theme/REMOVE_THEME_PALETTE', id);

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'palette has been deleted' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   */
  CHANGE_FABRIC_OBJECT_PROPERTY_COLOR: function (context, { color, swatchName, objectProperty, $fabric, fabricObject = null }) {
    let _fabricObject = fabricObject ? fabricObject : $fabric.canvas.getActiveObject(),
      _updateFabricRootField = context.rootState.fabric.hasActiveObject && context.rootState.fabric.activeObject.id === _fabricObject.id;

    const normalizedColor = color ? tinycolor(color).toHex8String() : null;

    console.log('CHANGE_FABRIC_OBJECT_PROPERTY_COLOR');

    // check valid params
    if ((color && !swatchName) || (!color && swatchName)) {
      this.dispatch('api/API_ERROR', { message: 'Error on change fabric color, you need to set color AND swatchName' });
      return false;
    }

    // check existing color in palette swatch
    if (color !== null && swatchName !== null) {
      let _index = context.state.palette.swatches.findIndex(
        value => tinycolor(value.color).toHex8String() === normalizedColor && value.name === swatchName
      );
      if (_index === -1) {
        // ERROR, swatch not exist in current palette
        this.dispatch('api/API_ERROR', { message: 'Error on change fabric color, color not find in current palette' });
        return false;
      }
    }

    // update color property on Fabric object and Fabric store
    if (_updateFabricRootField) {
      this.commit('fabric/UPDATE_ROOT_FIELD', { key: 'activeObject.' + objectProperty, value: normalizedColor });
    }
    $fabric.setObjectProp(objectProperty, normalizedColor, _fabricObject,
      objectProperty.indexOf('dropCapStyle') === -1
      && objectProperty.indexOf('bulletPointStyle') === -1
      && objectProperty.indexOf('endSignStyle') === -1
      && objectProperty.indexOf('textLinkStyle') === -1
    );

    // update paletteSwatchName on Fabric object and Fabric store
    const _newPaletteSwatchName = Object.assign({}, _fabricObject.paletteSwatchName);
    if (swatchName) {
      _newPaletteSwatchName[objectProperty] = swatchName;
    } else {
      delete _newPaletteSwatchName[objectProperty];
    }
    if (_updateFabricRootField) {
      this.commit('fabric/UPDATE_ROOT_FIELD', { key: 'activeObject.paletteSwatchName', value: _newPaletteSwatchName });
    }
    $fabric.setObjectProp('paletteSwatchName', _newPaletteSwatchName, _fabricObject, true);
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
