import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Utils from "~/common/utils/store";
/**
 * Adminsettings store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadAdminsettings: "adminsettings/LOAD_ADMINSETTINGS",
      saveAdminsettings: "adminsettings/SAVE_ADMINSETTINGS",
    }),
    ...mapMutations({
      updateAdminsettingsField: "adminsettings/UPDATE_FIELD",
      updateAdminsettingsArray: "adminsettings/UPDATE_ARRAY",
      resetAdminsettings:"adminsettings/RESET_ADMINSETTINGS",
      resetStateAdminsettings:"adminsettings/RESET_STATE"
    }),
    },

      computed: {
    ...mapState("adminsettings", ["adminsettings"]),
    ...mapGetters("adminsettings", []),
      }
 */
/**
 * return default adminsettings object
 */
function initialState() {
  return {
    _id: null,
    neededAppBuild: '0.0.0',
    maintenance: false,
    maintenanceMessage: {
      title: '',
      description: ''
    },
    publicMessage: {
      description: '',
      startAt: null,
      endAt: null,
      isClosable: false
    },
  }
}

const getDefaultState = () => {
  return {
    adminsettings: initialState()
  }
}

// initial state
const state = getDefaultState;

// getters are functions
const getters = {};

// mutations
const mutations = {

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.adminsettings
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.adminsettings, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.adminsettings
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state.adminsettings[payload.key].findIndex(payload.cb) : state.adminsettings[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.adminsettings[payload.key].splice(_index, 1);
      }
    }
    else if (payload.update === true) {
      const _index = payload.cb
        ? state.adminsettings[payload.key].findIndex(payload.cb)
        : state.adminsettings[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.adminsettings[payload.key].splice(_index, 1, payload.value);
      }
    }
    else {
      state.adminsettings[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.adminsettings
   */
  RESET_ADMINSETTINGS: (state) => {
    state.adminsettings = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }

};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {

  /**
   * fetch current adminsettings object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} adminsettingsID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_ADMINSETTINGS: async function (context, payload) {

    return this.$axios.get("/api/v1/admin/adminsettings" + (payload && payload.fromAdmin ? "?fromAdmin=1" : ""))
      .then(r => r.data)
      .then(
        adminsettings => {

          this.commit("adminsettings/UPDATE_FIELD", { key: 'neededAppBuild', value: adminsettings.neededAppBuild });
          this.commit("adminsettings/UPDATE_FIELD", { key: 'maintenance', value: adminsettings.maintenance });
          this.commit("adminsettings/UPDATE_FIELD", { key: 'maintenanceMessage', value: adminsettings.maintenanceMessage });
          this.commit("adminsettings/UPDATE_FIELD", { key: 'publicMessage', value: adminsettings.publicMessage });

          this.commit("adminsettings/UPDATE_FIELD", { key: '_id', value: adminsettings._id });

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'Admin settings is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * upsert current adminsettings object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_ADMINSETTINGS: function (context, payload) {
    return this.$axios.put("/api/v1/admin/adminsettings", context.state.adminsettings)
      .then(r => r.data)
      .then(
        adminsettings => {

          this.commit("adminsettings/UPDATE_FIELD", { key: '_id', value: adminsettings._id });
          this.commit("adminsettings/UPDATE_FIELD", { key: 'neededAppBuild', value: adminsettings.neededAppBuild });
          this.commit("adminsettings/UPDATE_FIELD", { key: 'maintenance', value: adminsettings.maintenance });
          this.commit("adminsettings/UPDATE_FIELD", { key: 'maintenanceMessage', value: adminsettings.maintenanceMessage });
          this.commit("adminsettings/UPDATE_FIELD", { key: 'publicMessage', value: adminsettings.publicMessage });


          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'Admin settings is saved', display: true });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
