import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Utils from "~/common/utils/store";
/**
 * Offer store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadOffer: "offer/LOAD_OFFER",
      loadOffers:"offer/LOAD_OFFERS",
      countOffers: "offer/COUNT_OFFERS",
      saveOffer: "offer/SAVE_OFFER",
      deleteOffer: "offer/DELETE_OFFER"
    }),
    ...mapMutations({
      updateOfferField: "offer/UPDATE_FIELD",
      updateOfferRootField: "offer/UPDATE_ROOT_FIELD",
      updateOfferArray: "offer/UPDATE_ARRAY",
      swapOfferArray: "offer/SWAP_ARRAY",
      updateOfferRootArray: "offer/UPDATE_ROOT_ARRAY",
      setCurrentOffer:"offer/SET_CURRENT_OFFER",
      setOffers:"offer/SET_OFFERS",
      addOffer:"offer/ADD_OFFER",
      updateOffer:"offer/UPDATE_OFFER",
      removeOffer:"offer/REMOVE_OFFER",
      resetOffer:"offer/RESET_OFFER",
      resetOffers:"offer/RESET_OFFERS",
      resetStateOffer:"offer/RESET_STATE"
    }),
    },

      computed: {
    ...mapState("offer", ["offers","offer","offersCount","offersPayload"]),
    ...mapGetters("offer", ["getDefaultOffer","getOffers","listOfferTag","listOfferMarketType","listExistingAccess","listExistingLimits"]),
      }
 */
/**
 * return default offer object
 */
function initialState() {
  return {
    _id: null,
    name: '',
    isPublic: false,
    isDefault: false,
    tag: 'free',// values  in ['free', 'premium', 'gold', 'platinum', 'education', 'education_project', 'enterprise', 'custom']
    marketType: 'B2C',// values  in [B2C, COMIN, EDU, B2B]
    duration: {
      durationTime: 1,
      durationDate: '',
      durationUnit: 'year',
    },
    offerSettings: {
      availableTemplates: [],
      limits: {
        numberWorkspaceUsers: - 1,
        numberCollaborators: -1,
        numberPages: -1,
      },
      accessList: []
    },
    stripePriceId: null,
    productPrice: null,
    upgradeIndex: 0,
  }
}

function _formatOfferForList(offer) {
  // TODO : set/update specific field to set in Offers list if needed
  return offer;
}

const getDefaultState = () => {
  return {
    offers: [],
    offersCount: 0,
    offersPayload: null,
    offer: initialState()
  }
}

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  /**
   * return collection offers
   */
  getOffers: state => state.offers,

  /**
   * return defaut state of offer object
   */
  getDefaultOffer: state => initialState(),

  listOfferTag: state => {
    return ['free', 'premium', 'gold', 'platinum', 'education', 'education_project', 'enterprise', 'custom'].map(tag => {
      return { value: tag, image: '/badges/' + tag + '.svg' }
    });
  },
  offerTagData: state => {
    return {
      'free': { textColor: '#fff', backgroundColor: '#bdbdbd' },
      'premium': { textColor: '#fff', backgroundColor: '#2f80ed' },
      'gold': { textColor: '#fff', backgroundColor: '#f2af4a' },
      'platinum': { textColor: '#828282', backgroundColor: '#d7d7d7' },
      'education': { textColor: '#fff', backgroundColor: '#25b585' },
      'education_project': { textColor: '#fff', backgroundColor: '#25b585' },
      'enterprise': { textColor: '#fff', backgroundColor: '#cc3333' },
      'custom': { textColor: '#fff', backgroundColor: '#9b51e0' },
    }
  },

  listOfferMarketType: state => {
    return [
      { value: 'B2C', label: 'B2C' },
      { value: 'COMIN', label: "Com'In" },
      { value: 'EDU', label: 'Education' },
      { value: 'B2B', label: 'B2B' },
    ];
  },

  listExistingAccess: state => {
    return {
      // access for workspace and organization
      workspace: [
        { value: 'organization:addWorkspace', label: 'Add workspace' },
        { value: 'magazine:createFrom:all', label: 'All public published templates' },
        { value: 'magazine:createWithPublicOffer', label: 'Create magazine with public offer' },
        { value: 'offer:hideUpgradeAction', label: 'Hide upgrade plan' },
        { value: 'workspace:exportMagazinesZip', label: 'Export workspace magazine (ZIP)' },
      ],
      // access for magazine
      magazine: [
        { value: 'collaboration:create', label: 'Activate collaboration' },
        { value: 'magazine:pdfRequest:withoutWatermark', label: 'PDF without watermark' },
        { value: 'magazine:pdfRequest:web', label: 'Access to PDF web' },
        { value: 'magazine:pdfRequest:hd', label: 'Access to PDF hd' },
        { value: 'magazine:pdfRequest:print', label: 'Access to PDF print (hd with margin)' },
        { value: 'magazine:pdfRequest:viewercode', label: 'Access to viewer zip code' },
        { value: 'magazine:printRequest', label: 'Access print order' },
        { value: 'magazine:privacyManagement', label: 'Privacy management (no index)' },
        { value: 'magazine:basicStats', label: 'Basic stats (# of views)' },
        { value: 'magazine:trackingViewer', label: 'Tracking viewer (Analytics)' },
        { value: 'magazine:personnalizeViewer', label: 'Personnalize viewer (Logo and Link)' },
      ]
    };
  },
  listExistingLimits: state => {
    return {
      // limits for workspace
      workspace: [
        { value: 'numberWorkspaceUsers', label: 'Nb max workspace users' }
      ],
      // limits for magazine
      magazine: [
        { value: 'numberCollaborators', label: 'Nb max collaborators' },
        { value: 'numberPages', label: 'Nb max pages' },
      ]
    };
  }
};
// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} offers
   *
   * set collections data offers
   */
  SET_OFFERS: (state, offers) => {
    state.offers = offers;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add offer object in collection from payload.offer parameter
   */
  ADD_OFFER: (state, payload) => {
    state.offers.push(_formatOfferForList(payload.offer));
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update offer object in collection from payload.offer parameter
   */
  UPDATE_OFFER: (state, payload) => {
    const _index = state.offers.findIndex(offer => offer._id === payload.offer._id);
    if (_index >= 0) {
      Object.assign(state.offers[_index], _formatOfferForList(payload.offer));
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} offerID
   *
   * remove offer object from collection with offerID
   */
  REMOVE_OFFER: (state, offerID) => {
    const _index = state.offers.findIndex(offer => offer._id === offerID);
    if (_index >= 0) {
      state.offers.splice(_index, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} offerID
   *
   * set current offer object from collection with offerID
   */
  SET_CURRENT_OFFER: (state, offerID) => {
    if (offerID === null) {
      state.offer = Object.assign({}, initialState());
    }
    else {
      state.offer = Object.assign(
        {},
        state.offer,
        state.offers.find(offer => offer._id == offerID)
      );
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.offer
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.offer, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.offer
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state.offer[payload.key].findIndex(payload.cb) : state.offer[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.offer[payload.key].splice(_index, 1);
      }
    }
    else {
      state.offer[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.offer
   */
  SWAP_ARRAY: (state, payload) => {
    let oldVal = state.offer[payload.key][payload.from];
    state.offer[payload.key][payload.from] = state.offer[payload.key][payload.to];
    Vue.set(state.offer[payload.key], payload.to, oldVal)
  },

  /**
 * @param {Object} state - https://vuex.vuejs.org/guide/state.html
 * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
 *
 * update one array field of state
 */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    }
    else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.offer
   */
  RESET_OFFER: (state) => {
    state.offer = Object.assign({}, initialState());
  },
  RESET_OFFERS: (state) => {
    state.offers = [];
    state.offersCount = 0;
    state.offersPayload = null;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState);
  }

};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of offers
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_OFFERS: async function (context, payload = { filter: {} }) {
    // don't reload offers list if already in state
    if (context.state.offers !== null && context.state.offers.length > 0 && context.state.offersPayload === JSON.stringify(payload)) {
      console.log('LOAD_OFFERS already in state');
      return Promise.resolve(true);
    }

    this.commit('offer/UPDATE_ROOT_FIELD', { key: 'offersPayload', value: JSON.stringify(payload) });

    return this.$axios.get("/api/v1/offer?filter=" + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        offers => {
          this.commit("offer/SET_OFFERS", offers);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'OFFERS are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);

          return false;
        }
      );
  },

  /**
  * fetch number of offers
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} query - see more https://github.com/loris/api-query-params
  * @return Promise
  */
  COUNT_OFFERS: async function (context, payload) {
    return this.$axios
      .get('/api/v1/offer/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('offer/UPDATE_ROOT_FIELD', { key: 'offersCount', value: count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT OFFERS are loaded' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * fetch current offer object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} offerID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_OFFER: async function (context, payload) {
    // don't reload offer if already in state
    if (payload === context.state.offer._id) {
      console.log('LOAD_OFFER already in state - ' + payload);
      return Promise.resolve(true);
    }

    return this.$axios.get("/api/v1/offer/" + Utils.normalizePayloadURI(payload, 'offerId'))
      .then(r => r.data)
      .then(
        offer => {

          this.commit("offer/UPDATE_FIELD", { key: 'name', value: offer.name });
          this.commit("offer/UPDATE_FIELD", { key: 'isPublic', value: offer.isPublic });
          this.commit("offer/UPDATE_FIELD", { key: 'isDefault', value: offer.isDefault });
          this.commit("offer/UPDATE_FIELD", { key: 'tag', value: offer.tag });
          this.commit("offer/UPDATE_FIELD", { key: 'marketType', value: offer.marketType });
          this.commit("offer/UPDATE_FIELD", { key: 'duration', value: offer.duration });
          this.commit("offer/UPDATE_FIELD", { key: 'offerSettings', value: offer.offerSettings });
          this.commit("offer/UPDATE_FIELD", { key: 'stripePriceId', value: offer.stripePriceId });
          this.commit("offer/UPDATE_FIELD", { key: 'productPrice', value: offer.productPrice });
          this.commit("offer/UPDATE_FIELD", { key: 'upgradeIndex', value: offer.upgradeIndex });
          this.commit("offer/UPDATE_FIELD", { key: '_id', value: offer._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'offer is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * upsert current offer object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_OFFER: function (context, payload) {
    let method = context.state.offer._id === null ? "post" : "put";
    let updateId = context.state.offer._id === null ? "" : context.state.offer._id;

    return this.$axios[method]("/api/v1/offer/" + updateId, context.state.offer)
      .then(r => r.data)
      .then(
        offer => {
          this.commit("offer/UPDATE_FIELD", { key: '_id', value: offer._id });

          this.commit("offer/UPDATE_FIELD", { key: 'name', value: offer.name });
          this.commit("offer/UPDATE_FIELD", { key: 'isPublic', value: offer.isPublic });
          this.commit("offer/UPDATE_FIELD", { key: 'isDefault', value: offer.isDefault });
          this.commit("offer/UPDATE_FIELD", { key: 'tag', value: offer.tag });
          this.commit("offer/UPDATE_FIELD", { key: 'marketType', value: offer.marketType });
          this.commit("offer/UPDATE_FIELD", { key: 'duration', value: offer.duration });
          this.commit("offer/UPDATE_FIELD", { key: 'offerSettings', value: offer.offerSettings });
          this.commit("offer/UPDATE_FIELD", { key: 'stripePriceId', value: offer.stripePriceId });
          this.commit("offer/UPDATE_FIELD", { key: 'productPrice', value: offer.productPrice });
          this.commit("offer/UPDATE_FIELD", { key: 'upgradeIndex', value: offer.upgradeIndex });

          if (updateId.length === 0) {
            this.commit("offer/ADD_OFFER", { offer });
          } else {
            this.commit("offer/UPDATE_OFFER", { offer });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'offer is saved', display: true });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * delete current offer object
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  DELETE_OFFER: function (context, payload) {
    let id = payload.id || context.state.offer._id

    return this.$axios.delete("/api/v1/offer/" + id)
      .then(r => r.data)
      .then(
        offer => {
          this.commit("offer/REMOVE_OFFER", id);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'offer has been deleted' });

          this.commit("offer/RESET_OFFER");
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  }
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};



export default store;
