<template>
  <el-tooltip
    :content="tooltip"
    :disabled="!tooltip"
    :placement="tooltipPlacement"
  >
    <el-button
      :circle="!label"
      :plain="noBorder !== true"
      :disabled="disabled"
      v-bind:class="{
        'el-label-button': label,
        'el-big-button': type === 'big',
        'el-large-button': type === 'large',
        'el-medium-button': type === 'medium',
        'el-no-border-button': noBorder,
        'is-selected': selected,
      }"
      @click="onClickEvent"
    >
      <span v-if="label" class="span-label">{{ label }}</span>
      <img :src="src" :class="{ shake: animationShake }" />
      <el-tag size="mini" v-if="tag">{{ tag }}</el-tag>
    </el-button>
  </el-tooltip>
</template>

<script>
export default {
  name: "MzIconButton",
  props: [
    "src",
    "label",
    "disabled",
    "tag",
    "type",
    "selected",
    "noBorder",
    "disableStopEvent",
    "keepFocusOnClick",
    "tooltip",
    "tooltipPlacement",
  ],
  data() {
    return {
      animationShake: false,
    };
  },
  methods: {
    onClickEvent(event) {
      if (!this.disableStopEvent) {
        event.stopImmediatePropagation();
      }
      if (!this.keepFocusOnClick) {
        event.target.blur();
      }
      this.$emit("click");
    },
    shake(delay = 0) {
      if (delay <= 0) {
        this.animationShake = true;
        setTimeout(() => {
          this.animationShake = false;
        }, 500);
      } else {
        setTimeout(() => {
          this.shake(0);
        }, delay);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.el-button {
  position: relative;
  border-width: 2px;
  padding: 9px 10px !important;

  &.is-selected {
    color: $public-color-blue;
    border-color: $public-color-blue;
  }

  & img {
    width: 16px;
    height: 16px;
  }

  &.is-disabled {
    & img {
      opacity: 0.25;
    }
  }
  & /deep/ * {
    /* disabled all pointer events on button child for get button as target event */
    pointer-events: none;
  }
}

.el-medium-button {
  padding: 13px 14px !important;

  & img {
    width: 16px;
    height: 16px;
  }
}

.el-large-button {
  padding: 17px 18px !important;

  & img {
    width: 24px;
    height: 24px;
  }
}

.el-big-button {
  padding: 21px 22px !important;

  & img {
    width: 32px;
    height: 32px;
  }
}

.el-tag {
  top: 0px;
  position: absolute;
  color: $public-color-blue;
  font-size: 0.63rem;
  font-weight: bold;
  background-color: $public-color-grey-bg;
  border-color: white;
  border-width: 2px;
  border-radius: 50%;
}

.el-label-button {
  border-radius: 30px;
  color: $public-color-black;

  & span {
    margin: 0 $margin-0;
  }
  & img {
    margin: 0 $margin-0;
  }

  & .el-tag {
    margin: 0;
  }
  & .span-label {
    top: -1px;
    position: relative;
  }
}

.el-no-border-button {
  border: 1px solid transparent !important;
  padding: 5px !important;

  &.el-large-button {
    padding: 10px !important;
  }

  // & img {
  //   width: 24px;
  //   height: 24px;
  // }

  &:focus {
    background-color: white;
  }
  &:hover {
    background-color: $public-color-grey-bg;
  }

  &.is-selected {
    background-color: $public-color-grey-bg;
    &:focus {
      background-color: $public-color-grey-bg;
    }
    &:hover {
      background-color: white;
      border-color: $public-color-grey-bg !important;
    }
  }
}

.shake {
  animation: shake 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>