import Vue from 'vue';

import 'quill/dist/quill.core.css' // import styles
//import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

import VueQuillEditor from 'vue-quill-editor'
import Quill from "quill";

const quillEditorOptions = {
  theme: false,
  modules: {
    toolbar: false,
    clipboard: {
      matchVisual: false
    },
    keyboard: {
      bindings: {
        /* clear style on new line (when press enter) */
        enter: {
          key: 13,
          handler: function (range, context) {
            context.format = {};
            return true;
          }
        }
      }
    },
  }
}

const fabricStyleTags = {
  "h1": { fontSize: '2rem' },
  "h2": { fontSize: '1.75rem' },
  "h3": { fontSize: '1.5rem' },
  "h4": { fontSize: '1.4rem' },
  "h5": { fontSize: '1.3rem' },
  "h6": { fontSize: '1.2rem' },
  "h7": { fontSize: '1.1rem' },
  "span": { fontSize: '1rem' }
}


let Inline = Quill.import("blots/inline");
let Parchment = Quill.import("parchment");

/* create specific blot for fabric style */
class FabricStyleBlot extends Inline {
  static create(value) {
    let node = super.create();

    if (!fabricStyleTags[value.htmlTag]) {
      value.htmlTag = 'span';
    }

    node.setAttribute("fabric-style", value.style);
    node.setAttribute("fabric-key-style", value.key);
    node.className = value.htmlTag;

    const cssStyle = fabricStyleTags[value.htmlTag];
    for (let _key of Object.keys(cssStyle)) {
      node.style[_key] = cssStyle[_key];
    }

    return node;
  }

  static formats(node) {
    return {
      htmlTag: node.className,
      key: node.getAttribute("fabric-key-style"),
      style: node.getAttribute("fabric-style")
    };
  }
}
FabricStyleBlot.blotName = "fabricStyle";
FabricStyleBlot.tagName = "span";
Quill.register(FabricStyleBlot);

/* create specific blot for overflow style */
class OverflowBackgroundBlot extends Inline {
  static create(value) {
    let node = super.create();
    node.className = "overflowText";
    return node;
  }

  static formats(node) {
    return node.className;
  }
}
OverflowBackgroundBlot.blotName = "overflowBackground";
OverflowBackgroundBlot.tagName = "overflowSpan";
Quill.register(OverflowBackgroundBlot);

/* create specific link attribute for set title */
class LinkBlot extends Inline {
  static create(value) {
    let node = super.create();

    if (typeof value !== 'string') {
      node.setAttribute('href', value.href);
      node.setAttribute('title', value.title);
    }
    else {
      node.setAttribute('href', value);
      node.setAttribute('title', value);
    }
    return node;
  }

  static formats(node) {
    const format = {
      href: node.getAttribute('href'),
      title: node.getAttribute('title'),
    };
    // auto detect page link and set page name
    if (VueQuillEditor && VueQuillEditor.store && VueQuillEditor.store.getters['magazine/pageNameMap'] && format.href.indexOf('#page_') === 0) {
      const pageId = format.href.substr(6);
      if (VueQuillEditor.store.getters['magazine/pageNameMap'][pageId]) {
        format.title = VueQuillEditor.store.getters['magazine/pageNameMap'][pageId];
      }
    }
    if (!format.title || format.title.length === 0) {
      format.title = format.href;
    }
    return format;
  }
}
LinkBlot.blotName = 'link';
LinkBlot.tagName = 'a';
Quill.register(LinkBlot);


/* create specific styleAttibutor for text-align left support */
const TextAlignStyle = new Parchment.Attributor.Style('textAlign', 'text-align', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['left', 'right', 'center', 'justify'],
});
Quill.register(TextAlignStyle, true);

/* create specific styleAttibutor for line-height support */
const LineHeightStyle = new Parchment.Attributor.Style('lineHeight', 'line-height', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1', '1.25', '1.5', '2'],
});
Quill.register(LineHeightStyle, true);

// Force specific blot order
Inline.order = [
  "cursor",
  "inline", // Must be lower
  "underline",
  "strike",
  "italic",
  "bold",
  "script",
  "fabricStyle",
  "link",
  "overflowBackground",
  "code" // Must be higher
];

/* clear text format on paste */
let Clipboard = Quill.import('modules/clipboard')
let Delta = Quill.import('delta')

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault()
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData('text/plain').replace(/\n\n/g, '\n');
    let formatedText = '';

    const mapUnicodeToRegular = {
      56814: 97, // bold a: 56814 --> 97 | -56717
      56788: 65, // bold A: 56788 --> 65 | -56723
      56398: 97,  // italic a
      56372: 65, // italic A
      56346: 97,
      56320: 65,
      56450: 97,
      56424: 65,
      56866: 97,
      56840: 65,
      56918: 97,
      56892: 65,
      56710: 97,
      56684: 65,
      56554: 97,
      56528: 65,
      56658: 97,
      56632: 65,
      56502: 97,
      56476: 65,
    }
    const mapUnicodeKeys = Object.keys(mapUnicodeToRegular).map(value => parseInt(value)).sort().reverse();

    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i);

      if (charCode === 55349) {
        const nextCharCode = text.charCodeAt(i + 1);
        //console.log('char at ' + i + ' - ' + nextCharCode + ' /"' + String.fromCharCode(charCode, nextCharCode) + '"');
        for (const code of mapUnicodeKeys) {
          if (nextCharCode >= code) {
            formatedText += String.fromCharCode(nextCharCode - (code - mapUnicodeToRegular[code]))
            break;
          }
        }
        i++;
      } else {
        formatedText += String.fromCharCode(charCode);
      }
    }

    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(formatedText)
    this.quill.updateContents(delta, Quill.sources.USER)
    this.quill.setSelection(delta.length() - range.length, Quill.sources.SILENT)
    this.quill.scrollIntoView()
  }
}

Quill.register('modules/clipboard', PlainClipboard, true)

export default ({ store }) => {
  Vue.use(VueQuillEditor, quillEditorOptions);
  Vue.prototype.$fabricStyleBlotTagList = Object.keys(fabricStyleTags);

  // attach store to quill editor for access in link blot
  VueQuillEditor.store = store;
};