<template>
  <el-col class="el-col-cardoffer">
    <el-card
      v-if="offer"
      shadow="none"
      :body-style="bodyStyle"
      class="m-1 p-2"
      v-bind:class="{
        'alternate-color': alternateColor,
      }"
    >
      <div class="el-header">
        <div slot="header">
          <h2>{{ offer.name }}</h2>
          <h2>{{ productPrice }}</h2>
        </div>
      </div>

      <el-row class="mt-2">
        {{ $t("public.card_magazine_offer.forOnePublication") }}
      </el-row>
      <el-row class="mt-0">
        {{ $t("public.card_magazine_offer.templateChoice") }}
      </el-row>
      <el-row class="mt-0">
        {{ $t("public.card_magazine_offer.interactivity") }}
      </el-row>
      <el-row class="mt-0">
        {{
          offer.offerSettings.limits.numberCollaborators === -1
            ? $t("public.card_magazine_offer.illimitedCollaborator")
            : $tc(
                "public.card_magazine_offer.numberOfCollaborator",
                offer.offerSettings.limits.numberCollaborators - 1,
                { nb: offer.offerSettings.limits.numberCollaborators - 1 }
              )
        }}
      </el-row>
      <el-row class="mt-0">
        {{
          offer.offerSettings.accessList.indexOf("magazine:trackingViewer") >= 0
            ? $t("public.card_magazine_offer.advancedTracking")
            : $t("public.card_magazine_offer.basicTracking")
        }}
      </el-row>
      <el-row
        class="mt-0"
        v-if="
          offer.offerSettings.accessList.indexOf('magazine:pdfRequest:web') >= 0
        "
      >
        {{ $t("public.card_magazine_offer.pdfWeb") }}
      </el-row>
      <el-row
        class="mt-0"
        v-if="
          offer.offerSettings.accessList.indexOf('magazine:pdfRequest:print') >=
          0
        "
      >
        {{ $t("public.card_magazine_offer.pdfPrint") }}
      </el-row>
      <el-row
        class="mt-0"
        v-if="
          offer.offerSettings.accessList.indexOf(
            'magazine:privacyManagement'
          ) >= 0
        "
      >
        {{ $t("public.card_magazine_offer.privacyManagement") }}
      </el-row>
      <el-row
        class="mt-0"
        v-if="
          offer.offerSettings.accessList.indexOf(
            'magazine:personnalizeViewer'
          ) >= 0
        "
      >
        {{ $t("public.card_magazine_offer.personnalizeViewer") }}
      </el-row>
      <el-row
        class="mt-0"
        v-if="
          offer.offerSettings.accessList.indexOf(
            'magazine:pdfRequest:viewercode'
          ) >= 0
        "
      >
        {{ $t("public.card_magazine_offer.viewerCode") }}
      </el-row>

      <div class="card-footer">
        <el-row type="flex" justify="center" class="mt-2">
          <el-button v-if="isCurrent" :disabled="true" type="info">
            {{ $t("public.card_magazine_offer.btnIsCurrent") }}
          </el-button>
          <el-button
            v-else
            @click="$emit('select', offer)"
            :disabled="disabled"
            type="primary"
          >
            {{ $t("public.card_magazine_offer.btnChoose") }}
          </el-button>
        </el-row>

        <el-row type="flex" justify="center" class="mt-2">
          <el-link
            type="primary"
            href="https://madmagz.com/pricing"
            target="_blank"
          >
            {{ $t("public.card_magazine_offer.viewMore") }}
          </el-link>
        </el-row>
      </div>
    </el-card>
  </el-col>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ItemOffer",
  components: {},

  props: ["offer", "disabled", "isCurrent", "alternateColor"],

  data() {
    return {
      bodyStyle: { padding: "0px 0px 100px 0px" },
    };
  },
  computed: {
    productPrice() {
      if (this.offer.stripePriceId && this.offer.stripePriceId.length > 0) {
        if (
          this.offer.productPrice &&
          this.offer.productPrice.amount !== undefined &&
          this.offer.productPrice.currency !== undefined
        ) {
          switch (this.offer.productPrice.currency) {
            case "eur":
              return this.offer.productPrice.amount / 100 + " €";

            default:
              return (
                this.offer.productPrice.amount / 100 +
                " " +
                this.offer.productPrice.currency
              );
          }
        }
      }

      return "0 €";
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.el-col-cardoffer {
  & .el-header {
    padding: 1rem 0 0 0;
    font-weight: bold;
  }

  & .el-card {
    position: relative;
    height: 85%;
  }

  & .card-footer {
    position: absolute;
    bottom: 25px;
    width: 100%;
    left: 0px;
  }
}

.alternate-color {
  background-color: $public-color-blue;
  color: #fff;

  & h2 {
    color: #fff;
  }

  & .el-button.el-button--primary {
    background-color: #fff;
    border-color: #fff;
    color: $public-color-blue;

    &:hover {
      background: #c8e1fc;
      border-color: #c8e1fc;
    }
    &.is-disabled {
      background: #c8e1fc;
      border-color: #c8e1fc;
    }
  }

  & .el-link.el-link--primary {
    color: #fff;

    &:hover {
      color: #c8e1fc;
      text-decoration: underline;
    }
  }
}
</style>