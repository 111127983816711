<template>
  <el-tooltip
    :content="tooltipContent"
    placement="bottom"
    effect="light"
    :disabled="tooltipContent === null"
  >
    <span
      class="user-role"
      v-bind:class="{
        'is-pending': isPending,
        'is-disabled': isDisabled,
        'is-current-user': isCurrentUser,
      }"
    >
      {{
        isCurrentUser
          ? $t("public.collaboration.assign_modal.user_list_me")
          : name
      }}
      <el-tooltip v-if="tag" :content="tooltipTag" placement="top">
        <el-tag
          size="mini"
          :type="isCurrentUser ? 'primary' : 'info'"
          :effect="isCurrentUser ? 'dark' : 'plain'"
          class="ml-0"
          >{{ tag }}</el-tag
        >
      </el-tooltip>
    </span>
  </el-tooltip>
</template>

<script>
export default {
  name: "UserRoleTag",
  props: ["user"],
  computed: {
    name() {
      if (!this.user || !this.user.name) {
        return "";
      }
      return this.user.name;
    },
    tag() {
      if (!this.user || !this.user.role) {
        return null;
      }
      return this.$t("public.collaboration.roles_tag." + this.user.role);
    },
    tooltipTag() {
      if (!this.user || !this.user.role) {
        return null;
      }
      return this.$t("public.collaboration.roles." + this.user.role);
    },
    isPending() {
      return this.user && this.user.status === "pending";
    },
    isDisabled() {
      return this.user && this.user.status === "disabled";
    },
    isCurrentUser() {
      return this.user && this.user.isCurrentUser;
    },
    tooltipContent() {
      if (this.isPending) {
        return this.$t("public.collaboration.userInfo.tooltipUserNotActive");
      }
      if (this.isDisabled) {
        return this.$t("public.collaboration.userInfo.tooltipUserDisabled");
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~assets/css/mz-variables.scss";

.user-role {
  font-size: 0.75rem;
  overflow: auto;
  color: $public-color-grey-1;

  &.is-pending {
    opacity: 0.5;
  }
  &.is-disabled {
    opacity: 0.5;
    color: rgb(197, 53, 53);
  }
  &.is-current-user {
    color: $public-color-blue;
  }
}
</style>