<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="displayModal"
    :close-on-click-modal="false"
    width="75%"
    append-to-body
  >
    <div v-loading="loading">
      <el-row type="flex" justify="center">
        <el-col :span="24">
          <el-form label-width="250px" :disabled="editDisabled">
            <el-row type="flex" justify="start" align="top" class="mt-2">
              <el-col :span="8">
                <el-form-item label="Key">
                  <el-input
                    v-model="key"
                    placeholder="please input text"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Active debug">
                  <el-switch v-model="debugMode"></el-switch>
                </el-form-item>
              </el-col>

              <el-col :span="14">
                <el-form-item label="Strategy">
                  <el-select v-model="strategy">
                    <el-option
                      v-for="item in listAuthStrategyType"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </el-option>
                  </el-select>
                  <el-tooltip placement="right">
                <div slot="content">
                  <p>
                    <b> For cas-dynamic strategy :</b><br/>
                    Add %SSO_IDENTIFIER% variable in your authorization URL
                  </p>
                </div>

                <i class="el-icon-info ml-1"></i>
              </el-tooltip>
                </el-form-item>

                <el-form-item label="Result map">
                  <el-select
                    v-model="authResultMap"
                    @change="onSelectAuthResultMap"
                    :clearable="true"
                  >
                    <el-option :value="-1" label="Create new map">
                      <span>
                        <i class="el-icon-circle-plus-outline mr-1"></i>
                        Create new map
                      </span>
                    </el-option>
                    <el-option
                      v-for="item in authResultMaps"
                      :key="item._id"
                      :value="item._id"
                      :label="item.name"
                    >
                      {{ item.name }}
                    </el-option>
                  </el-select>
                  <span v-if="authResultMap" class="ml-2">
                    <el-button
                      size="small"
                      icon="el-icon-edit"
                      @click="
                        $refs.editAuthResultMapModal.showEdit(authResultMap)
                      "
                    >
                      Edit
                    </el-button>
                    <el-button
                      size="small"
                      icon="el-icon-document-copy"
                      @click="
                        $refs.editAuthResultMapModal.showDuplicate(
                          authResultMap
                        )
                      "
                    >
                      Duplicate
                    </el-button>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>

            <el-divider>Auth configuration</el-divider>

            <el-row class="mt-2">
              <el-col
                :span="18"
                v-if="displayAttribute.indexOf('clientId') >= 0"
              >
                <el-form-item label="Client ID">
                  <el-input v-model="clientId"></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="18"
                v-if="displayAttribute.indexOf('clientSecret') >= 0"
              >
                <el-form-item label="Client Secret">
                  <el-input v-model="clientSecret"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="18" v-if="displayAttribute.indexOf('scope') >= 0">
                <el-form-item label="Scope">
                  <el-select
                    v-model="scopeList"
                    :clearable="true"
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :placeholder="'Add scope'"
                    class="el-col el-col-24"
                  >
                    <el-option
                      v-for="(item, idx) in scopeList"
                      :key="idx + item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="18"
                v-if="displayAttribute.indexOf('urlAuth') >= 0"
              >
                <el-form-item label="Authorization URL">
                  <el-input v-model="urlAuth"></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="18"
                v-if="displayAttribute.indexOf('urlToken') >= 0"
              >
                <el-form-item label="Token URL">
                  <el-input v-model="urlToken"></el-input>
                </el-form-item>
              </el-col>
              <el-col
                :span="18"
                v-if="displayAttribute.indexOf('urlData') >= 0"
              >
                <el-form-item label="User data URL">
                  <el-input v-model="urlData"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-divider>
              Api endpoint access
              <el-tooltip placement="right">
                <div slot="content">
                  <p>
                    <b>Access with POST request</b>
                    <ul>
                      <li>{{ externalApiPOSTUrl }}</li>
                      <li>set your SECRET TOKEN in 'mz-external-token' header variable</li>
                      <li>response will send in JSON format</li>
                    </ul>
                  </p>
                  <p>
                    <b>Access with GET request (Pronote specific)</b>
                    <ul>
                      <li>{{ externalApiGETUrl }}</li>
                      <li>replace UAI and API_KEY in url with your specific variable</li>
                      <li>response will send in XML format</li>
                    </ul>
                  </p>
                  <p>
                    <b>JSON Response example</b>
                    <pre><small>{
    status: 'ok',
    err: '',
    ressources: [
        '000000000X' => [
            'offerStartDate' => '{Datetime début offre}',
            'offerEndDate' => '{Datetime expiration offre}'
        ],
        '000000000Y’ => [
            'offerStartDate' => '{Datetime début offre}',
            'offerEndDate' => '{Datetime expiration offre}'
        ],
    ]
}</small></pre>
                  </p>
                </div>

                <i class="el-icon-info ml-1"></i>
              </el-tooltip>
              </el-divider>

            <el-row class="mt-2">
              <el-col :span="18">
                <el-form-item label="Secret token">
                  <el-input v-model="apiAccessToken" :clearable="true">
                    <el-button
                      slot="append"
                      icon="el-icon-refresh-right"
                      @click="generateRandomApiAccessToken"
                    >
                      Generate
                    </el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="18">
                <el-form-item label="Expire at">
                  <el-date-picker v-model="apiAccessExpireAt" type="date">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="onClickCancel" v-t="'public.alert.cancel'"></el-button>
      <el-button
        v-if="!editDisabled"
        type="primary"
        v-t="'public.alert.confirm'"
        :disabled="validateButtonDisabled"
        @click="onClickValidate"
      ></el-button>
    </span>

    <EditAuthResultMapModal
      ref="editAuthResultMapModal"
      @save="onSaveAuthResultMap"
    />
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import MiscUtils from "~/common/utils/misc";

import EditAuthResultMapModal from "~/components/authStrategy/EditAuthResultMapModal";

export default {
  name: "EditAuthStrategyModal",

  components: { EditAuthResultMapModal },

  props: [],
  computed: {
    ...mapState("authStrategy", ["authStrategy", "authResultMaps"]),
    ...mapGetters("authStrategy", ["listAuthStrategyType"]),

    externalApiPOSTUrl() {
      return process.env.VUE_API_URL + "/externalApi/activeSchools";
    },
    externalApiGETUrl() {
      return (
        process.env.VUE_API_URL +
        "/externalApi/catalogueEtab/{uai}?apiKey={apiKey}"
      );
    },

    dialogTitle() {
      if (this.editDisabled) {
        return "Auth strategy information";
      } else {
        return this.authStrategy._id
          ? "Edit auth strategy"
          : "Create auth strategy";
      }
    },

    displayAttribute() {
      let attributes = [];
      switch (this.authStrategy.strategy) {
        case "oauth2":
          attributes = [
            "clientId",
            "clientSecret",
            "scope",
            "urlAuth",
            "urlToken",
            "urlData",
          ];
          break;

        case "cas":
        case "cas-dynamic":
          attributes = ["urlAuth"];
          break;

        case "mz1":
          attributes = ["urlAuth"];
          break;
      }

      return attributes;
    },

    key: {
      get() {
        return this.authStrategy.key;
      },
      set(val) {
        this.updateField({ key: "key", value: val });
      },
    },
    debugMode: {
      get() {
        return this.authStrategy.debugMode;
      },
      set(val) {
        this.updateField({ key: "debugMode", value: val });
      },
    },
    strategy: {
      get() {
        return this.authStrategy.strategy;
      },
      set(val) {
        this.updateField({ key: "strategy", value: val });
      },
    },
    authResultMap: {
      get() {
        return this.authStrategy.authResultMap;
      },
      set(val) {},
    },
    clientId: {
      get() {
        return this.authStrategy.clientId;
      },
      set(val) {
        this.updateField({ key: "clientId", value: val });
      },
    },
    clientSecret: {
      get() {
        return this.authStrategy.clientSecret;
      },
      set(val) {
        this.updateField({ key: "clientSecret", value: val });
      },
    },
    scopeList: {
      get() {
        return this.authStrategy && this.authStrategy.scope
          ? [...this.authStrategy.scope]
          : [];
      },
      set(val) {
        console.log("set scope with ", val);
        this.updateField({ key: "scope", value: val });
      },
    },
    urlAuth: {
      get() {
        return this.authStrategy.urlAuth;
      },
      set(val) {
        this.updateField({ key: "urlAuth", value: val });
      },
    },
    urlToken: {
      get() {
        return this.authStrategy.urlToken;
      },
      set(val) {
        this.updateField({ key: "urlToken", value: val });
      },
    },
    urlData: {
      get() {
        return this.authStrategy.urlData;
      },
      set(val) {
        this.updateField({ key: "urlData", value: val });
      },
    },
    apiAccessToken: {
      get() {
        return this.authStrategy.apiAccessToken;
      },
      set(val) {
        this.updateField({ key: "apiAccessToken", value: val });
      },
    },
    apiAccessExpireAt: {
      get() {
        return this.authStrategy.apiAccessExpireAt;
      },
      set(val) {
        if (val) {
          let expireAt = new Date(val);
          expireAt.setHours(23, 59, 59);
          this.updateField({ key: "apiAccessExpireAt", value: expireAt });
        } else {
          this.updateField({ key: "apiAccessExpireAt", value: null });
        }
      },
    },

    validateButtonDisabled() {
      return (
        this.loading === true || this.key === null || this.key.trim().length < 2
      );
    },
  },
  data() {
    return {
      loading: false,
      displayModal: false,
      editDisabled: false,
    };
  },

  mounted() {
    this.loadAuthResultMaps();
  },

  methods: {
    ...mapActions({
      loadAuthStrategy: "authStrategy/LOAD_AUTHSTRATEGY",
      saveAuthStrategy: "authStrategy/SAVE_AUTHSTRATEGY",
      loadAuthResultMaps: "authStrategy/LOAD_AUTHRESULTMAPS",
    }),
    ...mapMutations({
      updateField: "authStrategy/UPDATE_FIELD",
      resetAuthStrategy: "authStrategy/RESET_AUTHSTRATEGY",
    }),

    hide() {
      this.displayModal = false;
    },
    show(authStrategyId) {
      this.loading = true;
      this.loadAuthStrategy(authStrategyId).then(() => {
        this.loading = false;
      });
      this.editDisabled = true;
      this.displayModal = true;
    },
    showCreate() {
      this.resetAuthStrategy();
      this.editDisabled = false;
      this.displayModal = true;
    },
    showEdit(authStrategyId) {
      this.loading = true;
      this.loadAuthStrategy(authStrategyId).then(() => {
        this.loading = false;
      });
      this.editDisabled = false;
      this.displayModal = true;
    },
    showDuplicate(authStrategyId) {
      this.loading = true;
      this.resetAuthStrategy();
      this.loadAuthStrategy(authStrategyId).then(() => {
        this.updateField({
          key: "_id",
          value: null,
        });
        this.updateField({
          key: "key",
          value: this.authStrategy.key + "_duplicated",
        });
        this.loading = false;
      });
      this.editDisabled = false;
      this.displayModal = true;
    },
    onClickCancel() {
      this.hide();
    },
    onClickValidate() {
      if (this.editDisabled) {
        this.hide();
        return;
      }

      this.loading = true;

      this.saveAuthStrategy().then((success) => {
        this.loading = false;
        if (success) {
          this.$emit("save", this.authStrategy);
          this.hide();
        }
      });
    },
    onSelectAuthResultMap(value) {
      if (value === -1) {
        this.$refs.editAuthResultMapModal.showCreate();
      } else {
        console.log("set authResultMap", value);
        this.updateField({ key: "authResultMap", value: value ? value : null });
      }
    },
    onSaveAuthResultMap(value) {
      console.log("onSaveAuthResultMap", value);
      this.updateField({ key: "authResultMap", value: value._id });
    },
    generateRandomApiAccessToken() {
      this.updateField({ key: "apiAccessToken", value: MiscUtils.uniqName() });
    },
  },
};
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 1rem;
}
</style>