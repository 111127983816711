<template>
  <div class="text-edit-input">
    <el-autocomplete
      class="textEditInput"
      v-show="isEditingValue"
      ref="valueInput"
      v-model="tmpValue"
      @select="infosChangedHandler"
      @blur="cancelEditing"
      :maxlength="maxlength"
      :fetch-suggestions="autoSuggest"
      :popper-class="'autoSuggestPopover'"
      :trigger-on-focus="false"
      :select-when-unmatched="true"
    >
    </el-autocomplete>
    <div
      class="staticText"
      @click="switchEditingvalue"
      :class="{
        'edit-enable': !disableEditing,
        hidden: isEditingValue,
      }"
    >
      <MzSingleLineText class="textContent">
        {{ staticValue }}
      </MzSingleLineText>
      <i class="el-icon-edit edit-icon" v-if="displayEditIcon"></i>
    </div>
  </div>
</template>

<script>
import MzSingleLineText from "~/components/MzSingleLineText";

export default {
  name: "EditableText",
  components: { MzSingleLineText },

  props: {
    emptyValue: {
      type: String,
      default: "edit value",
    },
    disableEditing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    displayValue: {
      type: String,
    },
    maxlength: {
      type: Number,
    },
    autoSuggest: {
      type: Function,
      default: (query, cb) => {
        return cb([]);
      },
    },
    displayEditIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tmpValue: "No value",
      isEditingValue: false,
    };
  },
  methods: {
    infosChangedHandler() {
      this.isEditingValue = false;
      this.$emit("change", this.tmpValue);
    },
    cancelEditing() {
      setTimeout(() => {
        // hide after delay for wait user click on item
        this.isEditingValue = false;
      }, 500);
    },
    switchEditingvalue() {
      if (this.disableEditing) {
        return;
      }
      this.tmpValue = this.value;
      this.isEditingValue = !this.isEditingValue;
      if (this.isEditingValue) {
        this.$nextTick(() => {
          this.$refs.valueInput.focus();
        });
      }
    },
  },

  computed: {
    staticValue() {
      const value = this.displayValue ? this.displayValue : this.value;
      return !value || value.length === 0 ? this.emptyValue : value;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-edit-input {
  position: relative;

  & .staticText {
    display: block;
    position: relative;

    width: 100%;
    border: 1px solid rgba($color: #fff, $alpha: 0);
    border-radius: 5px;

    &.edit-enable {
      cursor: pointer;
      &:hover {
        border: 1px solid #ccc;
        & .textContent {
          padding: 2px 5px;
        }
      }
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    & .textContent {
      padding: 2px 0px;
      //   text-overflow: ellipsis;
      // white-space: nowrap;
      // overflow: hidden;
    }

    & .edit-icon {
      position: absolute;
      right: 5px;
      top: 15%;
      color: #969696;
    }
  }

  & .textEditInput {
    position: absolute;
    display: inline-block;
    width: 100%;

    & /deep/.el-input {
      font-size: unset;

      & .el-input__inner {
        line-height: unset;
        height: unset;
        padding: 2px 0px;
        color: unset;
      }
    }
  }
}
</style>

<style lang="scss">
.autoSuggestPopover {
  width: unset !important;
}
</style>
