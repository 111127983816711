import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Utils from "~/common/utils/store";
/**
 * Workspace store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadWorkspace: "workspace/LOAD_WORKSPACE",
      loadWorkspaces:"workspace/LOAD_WORKSPACES",
      countWorkspaces:"user/COUNT_WORKSPACES",
      saveWorkspace: "workspace/SAVE_WORKSPACE",
      updateWorkspace: "workspace/UPDATE_WORKSPACE",
      updateWorkspaceName: "workspace/UPDATE_WORKSPACE_NAME",
      updateWorkspaceUser: "workspace/UPDATE_WORKSPACE_USER",
      deleteWorkspace: "workspace/DELETE_WORKSPACE",
      invitUserInWorkspace: "workspace/INVIT_USER_IN_WORKSPACE",
      invitExistingUsersInWorkspace: "workspace/INVIT_EXISTING_USERS_IN_WORKSPACE",
      removeUserFromWorkspace: "workspace/REMOVE_USER_FROM_WORKSPACE",
      requestExportMagazinesZip: "workspace/REQUEST_EXPORT_MAGAZINES_ZIP",
    }),
    ...mapMutations({
      updateWorkspaceField: "workspace/UPDATE_FIELD",
      updateWorkspaceRootField: "workspace/UPDATE_ROOT_FIELD",
      updateWorkspaceArray: "workspace/UPDATE_ARRAY",
      swapWorkspaceArray: "workspace/SWAP_ARRAY",
      updateWorkspaceRootArray: "workspace/UPDATE_ROOT_ARRAY",
      setCurrentWorkspace:"workspace/SET_CURRENT_WORKSPACE",
      setWorkspaces:"workspace/SET_WORKSPACES",
      addWorkspace:"workspace/ADD_WORKSPACE",
      updateWorkspace:"workspace/UPDATE_WORKSPACE",
      removeWorkspace:"workspace/REMOVE_WORKSPACE",
      resetWorkspace:"workspace/RESET_WORKSPACE",
      resetStateWorkspace:"workspace/RESET_STATE",
      setWorkspacesCount:"user/SET_WORKSPACES_COUNT",
    }),
    },

      computed: {
    ...mapState("workspace", ["workspaces","workspace","workspacesCount"]),
    ...mapGetters("workspace", []),
      }
 */
/**
 * return default workspace object
 */
function initialState() {
  return {
    _id: null,
    ssoIdentifier: '',
    organization: null,
    name: '',
    status: 'active',
    users: [],// references model Users
    activeOffer: null,
    offers: [],
    recursiveActiveOffer: null,
    createdAt: null,
    exportedZips: null,
  }
}


const getDefaultState = () => {
  return {
    workspaces: [],
    workspacesCount: 0,
    workspace: initialState()
  }
}

// initial state
const state = getDefaultState;

// getters are functions
const getters = {};

// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} workspaces
   *
   * set collections data workspaces
   */
  SET_WORKSPACES: (state, workspaces) => {
    state.workspaces = workspaces;
  },

  /**
 * @param {Object} state - https://vuex.vuejs.org/guide/state.html
 * @param {Object} payload - { count }
 *
 * set count data workspaces
 */
  SET_WORKSPACES_COUNT: (state, { count }) => {
    state.workspacesCount = count;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add workspace object in collection from payload.workspace parameter
   */
  ADD_WORKSPACE: (state, payload) => {
    state.workspaces.push(payload.workspace);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update workspace object in collection from payload.workspace parameter
   */
  UPDATE_WORKSPACE: (state, payload) => {
    const _index = state.workspaces.findIndex(workspace => workspace._id === payload.workspace._id);
    if (_index >= 0) {
      Object.assign(state.workspaces[_index], payload.workspace);
    }

    if (state.workspace !== null && state.workspace._id === payload.workspace._id) {
      Object.assign(state.workspace, payload.workspace);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} workspaceID
   *
   * remove workspace object from collection with workspaceID
   */
  REMOVE_WORKSPACE: (state, workspaceID) => {
    const _index = state.workspaces.findIndex(workspace => workspace._id === workspaceID);
    if (_index >= 0) {
      state.workspaces.splice(_index, 1);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {String} workspaceID
   *
   * set current workspace object from collection with workspaceID
   */
  SET_CURRENT_WORKSPACE: (state, workspaceID) => {
    if (workspaceID === null) {
      state.workspace = Object.assign({}, initialState());
    }
    else {
      state.workspace = Object.assign(
        {},
        state.workspace,
        state.workspaces.find(workspace => workspace._id == workspaceID)
      );
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.workspace
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.workspace, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.workspace
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state.workspace[payload.key].findIndex(payload.cb) : state.workspace[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.workspace[payload.key].splice(_index, 1);
      }
    }
    else {
      state.workspace[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.workspace
   */
  SWAP_ARRAY: (state, payload) => {
    let oldVal = state.workspace[payload.key][payload.from];
    state.workspace[payload.key][payload.from] = state.workspace[payload.key][payload.to];
    Vue.set(state.workspace[payload.key], payload.to, oldVal)
  },

  /**
 * @param {Object} state - https://vuex.vuejs.org/guide/state.html
 * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
 *
 * update one array field of state
 */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = (payload.cb) ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    }
    else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.workspace
   */
  RESET_WORKSPACE: (state) => {
    state.workspace = Object.assign({}, initialState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  }

};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of workspaces
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_WORKSPACES: async function (context, payload) {

    return this.$axios.get('/api/v1/workspace/list?filter=' + JSON.stringify(payload.filter) + '&sort=' + payload.sort + '&limit=' + payload.limit + '&skip=' + payload.skip)
      .then(r => r.data)
      .then(
        workspaces => {
          this.commit("workspace/SET_WORKSPACES", workspaces);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'WORKSPACES are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * fetch number of workspaces
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} query - see more https://github.com/loris/api-query-params
  * @return Promise
  */
  COUNT_WORKSPACES: async function (context, payload) {
    return this.$axios
      .get('/api/v1/workspace/count?filter=' + JSON.stringify(payload.filter))
      .then(r => r.data)
      .then(
        count => {
          this.commit('workspace/SET_WORKSPACES_COUNT', { count });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'COUNT WORKSPACES are loaded' });
          return true;
        },
        err => {
          if (err && err.response) {
            this.dispatch('api/API_ERROR', err.response.data);
          }
          return false;
        }
      );
  },

  /**
   * fetch current workspace object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} workspaceID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_WORKSPACE: async function (context, payload) {

    return this.$axios.get("/api/v1/workspace/" + Utils.normalizePayloadURI(payload, 'workspaceId'))
      .then(r => r.data)
      .then(
        workspace => {

          this.commit("workspace/UPDATE_FIELD", { key: 'ssoIdentifier', value: workspace.ssoIdentifier });
          this.commit("workspace/UPDATE_FIELD", { key: 'name', value: workspace.name });
          this.commit("workspace/UPDATE_FIELD", { key: 'organization', value: workspace.organization });
          this.commit("workspace/UPDATE_FIELD", { key: 'status', value: workspace.status });
          this.commit("workspace/UPDATE_FIELD", { key: 'users', value: workspace.users });
          this.commit("workspace/UPDATE_FIELD", { key: 'offers', value: workspace.offers });
          this.commit("workspace/UPDATE_FIELD", { key: 'activeOffer', value: workspace.activeOffer });
          this.commit("workspace/UPDATE_FIELD", { key: 'recursiveActiveOffer', value: workspace.recursiveActiveOffer });
          this.commit("workspace/UPDATE_FIELD", { key: 'createdAt', value: workspace.createdAt });
          this.commit("workspace/UPDATE_FIELD", { key: 'exportedZips', value: workspace.exportedZips });
          this.commit("workspace/UPDATE_FIELD", { key: '_id', value: workspace._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'workspace is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * create current workspace object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_WORKSPACE: function (context, payload) {

    return this.$axios.post("/api/v1/workspace/", context.state.workspace)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit("workspace/UPDATE_FIELD", { key: '_id', value: workspace._id });

          this.commit("workspace/UPDATE_FIELD", { key: 'ssoIdentifier', value: workspace.ssoIdentifier });
          this.commit("workspace/UPDATE_FIELD", { key: 'name', value: workspace.name });
          this.commit("workspace/UPDATE_FIELD", { key: 'organization', value: workspace.organization });
          this.commit("workspace/UPDATE_FIELD", { key: 'status', value: workspace.status });
          this.commit("workspace/UPDATE_FIELD", { key: 'users', value: workspace.users });
          this.commit("workspace/UPDATE_FIELD", { key: 'offers', value: workspace.offers });
          this.commit("workspace/UPDATE_FIELD", { key: 'activeOffer', value: workspace.activeOffer });
          this.commit("workspace/UPDATE_FIELD", { key: 'recursiveActiveOffer', value: workspace.recursiveActiveOffer });
          this.commit("workspace/UPDATE_FIELD", { key: 'createdAt', value: workspace.createdAt });
          this.commit("workspace/UPDATE_FIELD", { key: 'exportedZips', value: workspace.exportedZips });
          this.commit("workspace/ADD_WORKSPACE", { workspace });

          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'workspace is saved', display: true });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * invit user in workspace
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  INVIT_USER_IN_WORKSPACE: function (context, payload) {

    return this.$axios.post("/api/v1/workspace/invitUser", payload)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit("workspace/UPDATE_WORKSPACE", { workspace });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'invit user success' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * invit list of existing users in workspace
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  INVIT_EXISTING_USERS_IN_WORKSPACE: function (context, payload) {

    return this.$axios.post("/api/v1/workspace/invitExistingUsers", payload)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit("workspace/UPDATE_WORKSPACE", { workspace });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'invit user success' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * remove user from workspace
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  REMOVE_USER_FROM_WORKSPACE: async function (context, payload) {

    return this.$axios.put("/api/v1/workspace/removeUser", payload)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit("workspace/UPDATE_WORKSPACE", { workspace });
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'remove user success' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * update a specific workspace
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @return Promise
  */
  UPDATE_WORKSPACE: async function (context, payload) {
    return this.$axios
      .put('/api/v1/workspace/' + payload.workspaceId, payload)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit('workspace/UPDATE_WORKSPACE', { workspace });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * update the workspace name
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @return Promise
  */
  UPDATE_WORKSPACE_NAME: async function (context, payload) {
    return this.$axios
      .put('/api/v1/workspace/' + payload.workspaceId + '/updateName', payload)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit('workspace/UPDATE_WORKSPACE', { workspace });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * update a specific workspace user
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @return Promise
  */
  UPDATE_WORKSPACE_USER: async function (context, payload) {
    return this.$axios
      .put("/api/v1/workspace/" + payload.workspaceId + "/updateUser", payload)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit('workspace/UPDATE_WORKSPACE', { workspace });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },



  /**
  * delete current workspace object
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {Object} payload
  * @return Promise
  */
  DELETE_WORKSPACE: function (context, payload) {
    let id = payload.id || context.state.workspace._id

    return this.$axios.delete("/api/v1/workspace/" + id)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit("workspace/REMOVE_WORKSPACE", id);
          this.dispatch('api/API_SUCCESS', { type: 'info', 'message': 'workspace has been deleted' });

          this.commit("workspace/RESET_WORKSPACE");
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  REQUEST_EXPORT_MAGAZINES_ZIP: async function (context, payload) {
    //this.commit('magazine/RESET_PDF_STATUS_STATE');

    return this.$axios
      .post('/api/v1/workspace/' + payload.workspaceId + '/requestMagazinesZip', payload)
      .then(r => r.data)
      .then(
        workspace => {
          this.commit('workspace/UPDATE_WORKSPACE', { workspace });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  // REQUEST_EXPORT_MAGAZINES_ZIP_STATUS: async function (context, { version, updateStore }) {
  //   return this.$axios
  //     .post('/api/v1/magazine/pdf/request/status', {
  //       magazineId,
  //       version
  //     })
  //     .then(r => r.data)
  //     .then(
  //       ({ status }) => {
  //         if (updateStore && status) {
  //           this.commit('magazine/UPDATE_ROOT_FIELD', { key: 'pdfStatus', value: status });
  //         }

  //         return status;
  //       },
  //       err => {
  //         if (err.response.data && err.response.data.missingSvgs && err.response.data.missingSvgs.length > 0) {
  //           // specific case, ask client to regenerate all missing svg for pages
  //           return { missingSvgs: err.response.data.missingSvgs };
  //         } else {
  //           this.dispatch('api/API_ERROR', err.response.data);
  //           return false;
  //         }
  //       });
  // },

};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};



export default store;
