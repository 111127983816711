<template>
  <div id="app">
    <el-main>
      <nuxt />
    </el-main>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {};
</script>

<style lang="scss" scoped>
</style>
