<template>
  <span>
    <el-button
      v-if="hasPermission || canUpgradeOffer"
      :type="buttonType"
      :size="size ? size : 'default'"
      :disabled="disabled || loading"
      :loading="loading"
      :class="buttonClass"
      @click="onClickEvent"
    >
      {{ buttonLabel }}
    </el-button>
  </span>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "MzCheckOfferAccessButton",

  components: {},

  props: [
    "type",
    "size",
    "disabled",
    "loading",
    "label",
    "permission", // default permission to check (if array, only one permission is needed to access)
    "permissionLimit", // check permission with limits (ex: 'magLimits:allowMaxPages:12' for limit to 12 pages by magazine)
    "isMagazinePermission", // check permission in magazine permissions list
    "buttonClass",
  ],

  computed: {
    ...mapState("magazine", ["magazine"]),
    ...mapGetters("api", ["getUID", "permissions", "checkPerm"]),
    ...mapGetters("magazine", ["checkMagazinePerm"]),

    buttonType() {
      if (!this.hasPermission) {
        return "info";
      } else {
        return this.type ? this.type : "primary";
      }
    },
    buttonLabel() {
      if (!this.hasPermission) {
        return this.$t("public.checkAccess.noAvailable");
      } else {
        return this.label;
      }
    },
    canUpgradeOffer() {
      return !this.checkPerm("offer:hideUpgradeAction");
    },

    hasPermission() {
      if (this.permission) {
        if (Array.isArray(this.permission)) {
          if (this.isMagazinePermission) {
            if (
              this.permission.find((perm) => this.checkMagazinePerm(perm)) ===
              undefined
            ) {
              return false;
            }
          } else {
            if (
              this.permission.find((perm) => this.checkPerm(perm)) === undefined
            ) {
              return false;
            }
          }
        } else {
          if (this.isMagazinePermission) {
            if (!this.checkMagazinePerm(this.permission)) {
              return false;
            }
          } else {
            if (!this.checkPerm(this.permission)) {
              return false;
            }
          }
        }
      }

      if (this.permissionLimit) {
        let userPermission;
        if (this.isMagazinePermission) {
          userPermission = this.magazine.userPermissions.find(
            (perm) => perm && perm.indexOf(this.permissionLimit.key) === 0
          );
        } else {
          userPermission = this.permissions.find(
            (perm) => perm && perm.indexOf(this.permissionLimit.key) === 0
          );
        }
        const userLimit = userPermission
          ? parseInt(userPermission.split(":")[2])
          : 0;
        // -1 for illimited
        if (userLimit !== -1 && this.permissionLimit.nb > userLimit) {
          return false;
        }
      }

      return true;
    },
  },
  methods: {
    onClickEvent(event_) {
      event_.stopImmediatePropagation();
      if (!this.hasPermission) {
        // check if lock by magazine permission and user is not the owner of this magazine
        if (this.isMagazinePermission) {
          if (this.magazine.owner != this.getUID) {
            this.$alert(
              this.$t("public.updateOfferForAccess.messageNotOwner"),
              this.$t("public.updateOfferForAccess.titleNotOwner"),
              {
                dangerouslyUseHTMLString: true,
              }
            );
            return;
          } else if (this.checkPerm("magazine:createWithPublicOffer")) {
            // show ugrade plan modal
            this.$bus.$emit("showUpgradeOffer");
            return;
          }
        }

        this.$alert(
          this.$t("public.updateOfferForAccess.message"),
          this.$t("public.updateOfferForAccess.title"),
          {
            dangerouslyUseHTMLString: true,
            showConfirmButton: false,
            showCancelButton: true,
            // confirmButtonText: this.$t(
            //   "public.updateOfferForAccess.confirmButton"
            // ),
            cancelButtonText: this.$t(
              "public.updateOfferForAccess.cancelButton"
            ),
          }
        );

        return;
      }
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
</style>