import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import Utils from '~/common/utils/store';
import MiscUtils from '~/common/utils/misc';

/**
 * Format store
 *
 * register methods in your components with import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
 *
 * pick only the method you need
 *
   methods :{
  ...mapActions({
      loadFormat: "format/LOAD_FORMAT",
      loadFormatBase: "format/LOAD_FORMAT_BASE",
      loadFormatBaseList:"format/LOAD_FORMAT_BASE_LIST",
      saveFormat: "format/SAVE_FORMAT",
      saveFormatBase: "format/SAVE_FORMAT_BASE",
   //   getGelatoPrice: "format/GET_GELATO_PRICE"
    }),
    ...mapMutations({
      updateFormatField: "format/UPDATE_FIELD",
      updateFormatFieldBase: "format/UPDATE_FIELD_BASE",
      updateFormatRootField: "format/UPDATE_ROOT_FIELD",
      updateFormatArray: "format/UPDATE_ARRAY",
      updateFormatArrayBase: "format/UPDATE_ARRAY_BASE",
      swapFormatArray: "format/SWAP_ARRAY",
      updateFormatRootArray: "format/UPDATE_ROOT_ARRAY",
      setFormatBaseList:"format/SET_FORMAT_BASE_LIST",
      resetFormat:"format/RESET_FORMAT",
      resetFormatBase:"format/RESET_FORMAT_BASE",
      resetStateFormat:"format/RESET_STATE",
      updateCanvasSize: "format/UPDATE_CANVAS_SIZE",
    //  convertUnity: "format/CONVERT_UNITY",
    }),
    },

      computed: {
    ...mapState("format", ["formatBaseList","formatBase","format"]),
    ...mapGetters("format", ["orientation"]),
      }
 */
/**
 * return default format object
 */
function initialFormatState() {
  return {
    _id: null,
    formatBase: null /* ref to FormatBase model */,
    canvasWidth: 794,
    canvasHeight: 1123,
    dpi: 96,
    dpiLD: 96,
    dpiHD: 300,
    printMargins: {
      top: 5,
      left: 5,
      bottom: 5,
      right: 5
    },
    safeZone: {
      top: 5,
      left: 5,
      bottom: 5,
      right: 5
    },
    grid: {
      column: 0,
      gutter: 0
    }
  };
}

function initialFormatBaseState() {
  return {
    _id: null,
    name: "",
    pageWidth: 210,
    pageHeight: 297,
    orientation: 'Portrait', // values  in [Portrait, Landscape, Square],
    description: "",
    gelatoProducts: []
  };
}

const getDefaultState = () => {
  return {
    formatBaseList: [],
    formatBaseListQuery: null,
    format: initialFormatState(),
    formatBase: initialFormatBaseState()
  };
};

// initial state
const state = getDefaultState;

// getters are functions
const getters = {
  // orientation: state => {
  //   return state.format.pageWidth === state.format.pageHeight
  //     ? 'Square'
  //     : state.format.pageWidth > state.format.pageHeight
  //       ? 'Landscape'
  //       : 'Portrait';
  // }
};
// mutations
const mutations = {
  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {Object} formats
   *
   * set collections data formats
   */
  SET_FORMAT_BASE_LIST: (state, { formats, query }) => {
    state.formatBaseList = formats;
    state.formatBaseListQuery = query;
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * add format object in collection from payload.format parameter
   */
  ADD_FORMAT_BASE: (state, payload) => {
    state.formatBaseList.push(payload.format);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update format object in collection from payload.format parameter
   */
  UPDATE_FORMAT_BASE: (state, payload) => {
    console.log('UPDATE_FORMAT_BASE', JSON.parse(JSON.stringify(payload)))
    const _index = state.formatBaseList.findIndex(format => format._id === payload.format._id);
    if (_index >= 0) {
      Object.assign(state.formatBaseList[_index], payload.format);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.format
   */
  UPDATE_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state.format, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state.formatBase
   */
  UPDATE_FIELD_BASE: (state, { key, value }) => {
    Utils.setProperty(key, state.formatBase, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one field of state
   */
  UPDATE_ROOT_FIELD: (state, { key, value }) => {
    Utils.setProperty(key, state, value);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.format
   */
  UPDATE_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb
        ? state.format[payload.key].findIndex(payload.cb)
        : state.format[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.format[payload.key].splice(_index, 1);
      }
    } else {
      state.format[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state.formatBase
   */
  UPDATE_ARRAY_BASE: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb
        ? state.formatBase[payload.key].findIndex(payload.cb)
        : state.formatBase[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state.formatBase[payload.key].splice(_index, 1);
      }
    } else {
      state.formatBase[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * swap two elements of state.format
   */
  SWAP_ARRAY: (state, payload) => {
    let oldVal = state.format[payload.key][payload.from];
    state.format[payload.key][payload.from] = state.format[payload.key][payload.to];
    Vue.set(state.format[payload.key], payload.to, oldVal);
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   * @param {payload} payload - https://vuex.vuejs.org/guide/mutations.html#commit-with-payload
   *
   * update one array field of state
   */
  UPDATE_ROOT_ARRAY: (state, payload) => {
    if (payload.delete === true) {
      const _index = payload.cb ? state[payload.key].findIndex(payload.cb) : state[payload.key].indexOf(payload.value);
      if (_index >= 0) {
        state[payload.key].splice(_index, 1);
      }
    } else {
      state[payload.key].push(payload.value);
    }
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset state.format
   */
  RESET_FORMAT: state => {
    state.format = Object.assign({}, initialFormatState());
  },

  /**
  * @param {Object} state - https://vuex.vuejs.org/guide/state.html
  *
  * reset state.formatBase
  */
  RESET_FORMAT_BASE: state => {
    state.formatBase = Object.assign({}, initialFormatBaseState());
  },

  /**
   * @param {Object} state - https://vuex.vuejs.org/guide/state.html
   *
   * reset complete state to default value
   */
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },

  REFRESH_ORIENTATION_BASE: state => {
    state.formatBase.orientation =
      state.formatBase.pageWidth === state.formatBase.pageHeight
        ? 'Square'
        : state.formatBase.pageWidth > state.formatBase.pageHeight
          ? 'Landscape'
          : 'Portrait';
  },

  UPDATE_CANVAS_SIZE: (state) => {
    state.format.canvasWidth = MiscUtils.convertMilimetersToPixels(state.format.formatBase.pageWidth, state.format.dpi);
    state.format.canvasHeight = MiscUtils.convertMilimetersToPixels(state.format.formatBase.pageHeight, state.format.dpi);
  }

  // CONVERT_UNITY: (state, payload) => {
  //   let unitToConvert = (state.format[payload.from] = payload.value * 1);
  //   let convertionToApply = payload.from[0] === 'p' ? 'convertMilimetersToPixels' : 'convertPixelToMilimeters';
  //   let to = payload.from[0] === 'p' ? payload.from.replace('page', 'canvas') : payload.from.replace('canvas', 'page');

  //   state.format[to] = MiscUtils[convertionToApply](unitToConvert, state.format.dpi);
  // }
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  /**
   * fetch collection of formats base
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} query - see more https://github.com/loris/api-query-params
   * @return Promise
   */
  LOAD_FORMAT_BASE_LIST: async function (context, query = '') {
    // don't reload formats list if already in state
    if (context.state.formatBaseList.length > 0 && context.state.formatBaseListQuery === query) {
      console.log('LOAD_FORMAT_BASE_LIST already in state');
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/format/base/' + query)
      .then(r => r.data)
      .then(
        formats => {
          this.commit('format/SET_FORMAT_BASE_LIST', { formats, query });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'FORMAT_BASE_LIST are loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);

          return false;
        }
      );
  },

  /**
   * fetch current format object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {String} formatID
   * @param {String} query -see populate
   * @return Promise
   */
  LOAD_FORMAT: async function (context, payload) {
    // don't reload format if already in state
    if (payload === context.state.format._id) {
      console.log('LOAD_FORMAT already in state - ' + payload);
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/format/' + Utils.normalizePayloadURI(payload, 'formatId'))
      .then(r => r.data)
      .then(
        format => {
          this.commit('format/UPDATE_FIELD', { key: 'formatBase', value: format.formatBase });
          this.commit('format/UPDATE_FIELD', { key: 'canvasWidth', value: format.canvasWidth });
          this.commit('format/UPDATE_FIELD', { key: 'canvasHeight', value: format.canvasHeight });
          this.commit('format/UPDATE_FIELD', { key: 'dpi', value: format.dpi });
          this.commit('format/UPDATE_FIELD', { key: 'dpiLD', value: format.dpiLD });
          this.commit('format/UPDATE_FIELD', { key: 'dpiHD', value: format.dpiHD });
          this.commit('format/UPDATE_FIELD', { key: 'printMargins', value: format.printMargins });
          this.commit('format/UPDATE_FIELD', { key: 'safeZone', value: format.safeZone });
          this.commit('format/UPDATE_FIELD', { key: 'grid', value: format.grid });
          this.commit('format/UPDATE_FIELD', { key: '_id', value: format._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'format is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * upsert current format object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_FORMAT: function (context, payload) {
    let method = context.state.format._id === null ? 'post' : 'put';
    let updateId = context.state.format._id === null ? '' : context.state.format._id;

    return this.$axios[method]('/api/v1/format/' + updateId, context.state.format)
      .then(r => r.data)
      .then(
        format => {
          this.commit('format/UPDATE_FIELD', { key: '_id', value: format._id });
          this.commit('format/UPDATE_FIELD', { key: 'formatBase', value: format.formatBase });
          this.commit('format/UPDATE_FIELD', { key: 'canvasWidth', value: format.canvasWidth });
          this.commit('format/UPDATE_FIELD', { key: 'canvasHeight', value: format.canvasHeight });
          this.commit('format/UPDATE_FIELD', { key: 'dpi', value: format.dpi });
          this.commit('format/UPDATE_FIELD', { key: 'dpiLD', value: format.dpiLD });
          this.commit('format/UPDATE_FIELD', { key: 'dpiHD', value: format.dpiHD });
          this.commit('format/UPDATE_FIELD', { key: 'printMargins', value: format.printMargins });
          this.commit('format/UPDATE_FIELD', { key: 'safeZone', value: format.safeZone });
          this.commit('format/UPDATE_FIELD', { key: 'grid', value: format.grid });

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'format is saved' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
  * fetch current formatBase object
  * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
  * @param {String} formatID
  * @param {String} query -see populate
  * @return Promise
  */
  LOAD_FORMAT_BASE: async function (context, payload) {
    // don't reload format if already in state
    if (payload === context.state.formatBase._id) {
      console.log('LOAD_FORMAT_BASE already in state - ' + payload);
      return Promise.resolve(true);
    }

    return this.$axios
      .get('/api/v1/format/base/' + Utils.normalizePayloadURI(payload, 'formatId'))
      .then(r => r.data)
      .then(
        format => {
          this.commit('format/UPDATE_FIELD_BASE', { key: 'name', value: format.name });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'pageWidth', value: format.pageWidth });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'pageHeight', value: format.pageHeight });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'orientation', value: format.orientation });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'description', value: format.description });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'gelatoProducts', value: format.gelatoProducts });
          this.commit('format/UPDATE_FIELD_BASE', { key: '_id', value: format._id });
          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'formatBase is loaded' });

          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  /**
   * upsert current formatBase object
   * @param {Object} context - see more https://vuex.vuejs.org/guide/actions.html
   * @param {Object} payload
   * @return Promise
   */
  SAVE_FORMAT_BASE: function (context, payload) {
    let method = context.state.formatBase._id === null ? 'post' : 'put';
    let updateId = context.state.formatBase._id === null ? '' : context.state.formatBase._id;

    return this.$axios[method]('/api/v1/format/base/' + updateId, context.state.formatBase)
      .then(r => r.data)
      .then(
        format => {
          this.commit('format/UPDATE_FIELD_BASE', { key: '_id', value: format._id });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'name', value: format.name });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'pageWidth', value: format.pageWidth });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'pageHeight', value: format.pageHeight });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'orientation', value: format.orientation });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'description', value: format.description });
          this.commit('format/UPDATE_FIELD_BASE', { key: 'description', value: format.gelatoProducts });

          if (updateId.length === 0) {
            this.commit('format/ADD_FORMAT_BASE', { format });
          } else {
            this.commit('format/UPDATE_FORMAT_BASE', { format });
          }

          this.dispatch('api/API_SUCCESS', { type: 'info', message: 'format is saved' });
          return true;
        },
        err => {
          this.dispatch('api/API_ERROR', err.response.data);
          return false;
        }
      );
  },

  // NOT USED
  // GET_GELATO_PRICE: async function (context, payload) {
  //   const productUid = payload.productUid;
  //   delete (payload.productUid);

  //   return this.$axios
  //     .get('/api/v1/gelato/price/' + productUid, { params: payload })
  //     .then(r => r.data)
  //     .then(
  //       results => {
  //         return results;
  //       },
  //       err => {
  //         this.dispatch('api/API_ERROR', err.response.data);
  //         return false;
  //       }
  //     );
  // },
};

let store = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default store;
