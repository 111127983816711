export default {
  /**
   * @param {String|object} - payload
   * @param {String} - idKey
   *
   * return queryString for APi call
   */
  normalizePayloadURI(payload, idKey) {
    if (typeof payload == 'string' || payload instanceof String) {
      return payload;
    } else {
      let queryString = payload[idKey];

      if (!(typeof payload.query === 'undefined')) {
        queryString += payload.query;
      }

      return queryString;
    }
  },

  /**
   * A function to take a string written in dot notation style, and use it to
   * find a nested object property inside of an object.
   *
   * Useful in a plugin or module that accepts a JSON array of objects, but
   * you want to let the user specify where to find various bits of data
   * inside of each custom object instead of forcing a standardized
   * property list.
   *
   * @param {String} nested A dot notation style parameter reference (ie "urls.small")
   * @param {Object} object (optional) The object to search
   *
   * @return the value of the property in question
   */
  getProperty(propertyName, object) {
    let property = object || this;
    let parts = propertyName.split('.');

    for (let i = 0; i < parts.length; i++) {
      property = property[parts[i]];
    }

    return property;
  },

  /**
   * Seet property of an object at n level
   * @param {String} propertyName path of the prop to set ("prop.subprop.final")
   * @param {Object} object the object to search
   * @param {*} value the value to set
   */
  setProperty(propertyName, object, value, useSet = false) {
    let property = object || this;
    let parts = propertyName.split('.');

    if (parts.length === 1) {
      if (useSet) {
        property.set(propertyName, value);
      } else {
        property[propertyName] = value;
      }

      return;
    }

    for (let i = 0; i < parts.length - 1; i++) {
      property = property[parts[i]];
    }

    if (useSet) {
      property[parts[parts.length - 2]].set(parts[parts.length - 1], value);
    } else {
      property[parts[parts.length - 1]] = value;
    }
  }
};
